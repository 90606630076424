<template>
  <div class="hikvision-stream-container layout__body">
    
    <!-- 
      loading
    -->
    
    <v-overlay v-if="loading" opacity="1" :color="'#FFF'">
      <CPLoading />
    </v-overlay>
    
    <!-- 
      error
     -->

    <DashMessage
      v-if="serverErr && !loading"
      :message="message"
      hasXClose
      hasBtn
      @clear="handleBackNavigation"
      @btn-pressed="handleBackNavigation"
    ></DashMessage>

    <template>
      <div class="hikvision-stream-container" >
        <div v-for="(video, i) in hardcodedLinks" :key="i">
          <div class="video-stream" @click="showIframe(video.link)">
            <div class="icon"><img src="@/assets/dashboard/icons/live-video.svg" alt=""></div>
            <div class="label"><p>{{ video.name }}</p></div>
            <div><img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt=""></div>
          </div>
        </div>
        <!-- <div
          v-for="({link, title}, i) in URLS"
          :key="i"
          >
          <div class="video-stream" @click="showPlayer(link)">
            <div class="icon"><img src="@/assets/dashboard/icons/live-video.svg" alt=""></div>
            <div class="label"><p>{{ title }}</p></div>
            <div><img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt=""></div>
          </div>
        </div> -->
      </div>
    </template>

    <RobiRestream 
      v-if="playIframe"
      :URL="curIFrameStream"
      @close="closePlayer()"
    />
    
    <JSDecoder
      v-if="play"
      :URL="curStream"
      @close="closePlayer()"
    />
  </div>
</template>

<script>
import { backNavigateQueue } from "@/helpers/app.js";
import dashboardApi from '@/helpers/dashboardApi';
import JSDecoder from "@/components/Dashboard/JSDecoder.vue";
import RobiRestream from "@/components/Dashboard/RobiRestream.vue";
import DashMessage from '@/components/Dashboard/DashMessage.vue'
import CPLoading from '@/components/Dashboard/CPLoading.vue'
import bridge from "@/helpers/bridge";

export default {
  components: {
    DashMessage,
    JSDecoder,
    CPLoading,
    RobiRestream,
  },
  data() {
    return {
      scriptEle: null,
      projectId: this.$route.params.projectId,
      loading: false,
      serverErr: false,
      URLS: {},
      play: false,
      playIframe: false,
      playFullScreen: false,
      curStream: null,
      curIFrameStream: null,
      hardcodedLinks: [
        {
          name: 'ШК_СК-Бараки',
          link: 'https://cp-dashboard.erg.kz/wsscam/e3b61184-c433-4261-80cc-aef88c284f00.html'
        },
        {
          name: 'ШК_СК1',
          link: 'https://cp-dashboard.erg.kz/wsscam/577862a9-24cc-4d24-b831-d8fa1291885d.html'
        },
        {
          name: 'ШК_СК2',
          link: 'https://cp-dashboard.erg.kz/wsscam/7ff31633-33a1-48cc-937b-b8f0bf481a1c.html'
        },
      ]
    }
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    bridge.disableScreenCapture();
  },
  methods: {
    showIframe(link) {
      this.curIFrameStream = link;
      this.playIframe = !this.playIframe;
    },
    // show player
    showPlayer(link) {
      this.curStream = link;
      this.play = !this.play;
    },

    // close player
    closePlayer() {
      this.play = false;
      this.playIframe = false;
    },
    handleBackNavigation() {
      this.$router.replace({
        name: 'ProjectsPage'
      })
    },
    async getURLS() {
      try {
        const res = await dashboardApi(`/project/${this.projectId}/video/`);
        this.URLS = res.data.urls;
      } catch (error) {
        console.log(error);
        this.serverErr = true;
      } finally {
        this.loading = false;
      }
      this.loading = false;
    },
    dynamicallyLoadScript(url) {
      var script = document.createElement("script");
      script.src = url;
      this.scriptEle = document.body.appendChild(script);
    },
  },
  async created() {
		this.dynamicallyLoadScript('/js/videoStream/jsPlugin-1.2.0.min.js');
    // this.getURLS();
  },
  beforeDestroy() {
    document.body.removeChild(this.scriptEle);
    bridge.enableScreenCapture();
  },
  computed: {
    message() {
      return this.serverErr ? 'Ошибка сервера' : Object.keys(this.URLS).length === 0 ? 'Трансляции не найдены' : null
    },
    noStreams() {
      return Object.keys(this.URLS).length === 0 && true
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
