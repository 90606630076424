<template>
  <div>
    <div @click="toggleDatePicker">
      <Calendar :date='date' />
    </div>
    <DatePicker
      v-if="showDatePicker"
      v-on:closeOverlay="toggleDatePicker($event)"
      v-on:monthChanged="monthChanged($event)"
      v-on:dateChanged="setNewDate($event)"
      :min="budgetMinDate"
      :date="type == 'development' ? devDate : type == 'finance' ? finDate : null"
      :availableDates="availableDates"
      :loadingDates="loadingDates"
    />
  </div>
</template>

<script>
import Calendar from '@/components/Dashboard/Calendar.vue';
import dashboardApi from '@/helpers/dashboardApi';
import DatePicker from '@/components/Dashboard/DatePicker.vue';
import axios from 'axios';

export default {
  components:{
    Calendar,
    DatePicker,
  },
  data() {
    return {
      projectId: parseInt(this.$route.params.projectId),

      showDatePicker: false,
      availableDates: null,
      loadingDates: false,

      /*
        cancelToken
      */
      
      requestCancelToken: undefined,
    }
  },
  props: {
    type: {
      type: String,
    },
  },
  methods: {
    async getDev(date) {
      try {
        const res = await dashboardApi(`/project/${this.projectId}/budget/development/${date}/`);
        this.dev = res.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.devLoading = false;
      }
    },
    
    async getFin(date) {
      try {
        const res = await dashboardApi(`/project/${this.projectId}/budget/finance/${date}/`);
        this.fin = res.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.finLoading = false;
      }
    },

    /* 
      DatePicker
    */

    async monthChanged(month) {
      try {
        this.loadingDates = true;
        if (typeof this.requestCancelToken != typeof undefined) {
          this.requestCancelToken.cancel("Operation canceled due to new request.")
        }
        this.requestCancelToken = axios.CancelToken.source()
        const res = await dashboardApi(`/project/${this.projectId}/budget/total/date/${month}/`,
        { cancelToken: this.requestCancelToken.token });
        this.availableDates = res.data;
        this.loadingDates = false;
      } catch (error) {
        console.log(error);
      }
    },

    setNewDate(event) {
      console.log('set new date ' + event);
      if (this.type === 'development') {
        this.$store.dispatch('cp/changeDevDate', event)
      } else if (this.type === 'finance') {
        this.$store.dispatch('cp/changeFinDate', event);
      } return null
    },

    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
  },
  computed: {
    date() {
      if (this.type === 'development') {
        return this.$store.state.cp.devDate;
      } else if (this.type === 'finance') {
        return this.$store.state.cp.finDate;
      } return null
    },
    budgetMinDate() {
      let min = this.$store.state.cp.budgetMinDate;
      return min.replace(/(\d+)-(\d+)-(\d+)/, '$1-$2');
    },
    devDate() {
      return this.$store.state.cp.devDate;
    },
    finDate() {
      return this.$store.state.cp.finDate;
    },
  },
}
</script>