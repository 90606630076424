var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"elements"},_vm._l((_vm.data),function(ref){
var id = ref.id;
var title = ref.title;
var designer_fact = ref.designer_fact;
var revision_fact = ref.revision_fact;
var customer_fact = ref.customer_fact;
var flag = ref.flag;
var planned = ref.planned;
var customer_progress_planned = ref.customer_progress_planned;
var customer_progress_fact = ref.customer_progress_fact;
return _c('div',{key:id,staticClass:"element"},[_c('div',{staticClass:"element_title",on:{"click":_vm.toggeActive}},[_c('img',{attrs:{"src":require(("@/assets/dashboard/flag-icons/flag-" + flag + ".svg")),"alt":""}}),_c('p',[_vm._v(_vm._s(title))])]),_c('div',{staticClass:"table"},[_c('div',{staticClass:"element_planned"},[_c('p',[_vm._v(_vm._s(planned)+" ед")])]),_c('div',{staticClass:"element_bar"},[_c('div',{staticClass:"lines"},[_c('div',{staticClass:"line line1__bar",style:({
              'width': designer_fact === 0? '1%'
              : ((designer_fact / planned) * 100) === 100 ? '95%'
              : (designer_fact / planned) * 100 + '%'
            })}),(_vm.isActive)?_c('div',{staticClass:"line1__fact"},[_vm._v(_vm._s(designer_fact))]):_vm._e()]),_c('div',{staticClass:"lines"},[_c('div',{staticClass:"line line2__bar",style:({
              'width': revision_fact === 0 ? '1%'
              : (revision_fact / planned ) * 100 === 100 ? '94%' 
              : (revision_fact / planned ) * 100 + '%',
              'margin-left': 
              designer_fact === 0? '1%' 
              : ((designer_fact / planned) * 100) === 100 ? '95%' 
              : (designer_fact / planned) * 100 + '%'
            })}),(_vm.isActive)?_c('div',{staticClass:"line2__fact"},[_vm._v(" "+_vm._s(revision_fact))]):_vm._e()]),_c('div',{staticClass:"lines"},[_c('div',{staticClass:"line line3__bar",style:({
              'width': customer_fact === 0 ? '5%'
              : revision_fact === 0 && designer_fact === 0 ? (customer_fact / planned ) * 100 - 2 + '%' 
              : (customer_fact / planned ) * 100 + '%',
              'margin-left': revision_fact === 0 && designer_fact === 0 ? '2%' 
              : revision_fact === planned ? 95 + '%'
              : designer_fact === 0 && revision_fact !== 0 ? (revision_fact / planned ) * 100 + 1 + '%'
              // : designer_fact !== 0 && revision_fact === 0 ? (designer_fact / planned ) * 100 + 1 + '%'
              : ((designer_fact + revision_fact) / planned) * 100 === 100 ? '95%'
              : (((revision_fact / planned ) * 100) + ((designer_fact / planned) * 100)) + '%'
            })},[(_vm.isActive)?_c('div',{staticClass:"line3__fact"},[_vm._v(_vm._s(customer_fact))]):_vm._e()])])]),_c('div',{staticClass:"percents"},[_c('div',{staticClass:"percent"},[_c('p',[_vm._v(_vm._s(customer_progress_fact)+"%")])]),(_vm.isActive)?_c('div',{staticClass:"percent toggled"},[_c('p',[_vm._v(_vm._s(customer_progress_planned)+"%")])]):_vm._e()])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }