<template>
  <div v-if="item" class="bar">
    <div class="bar__progress">
      <div 
        class="filled"
        :style="{
          'background': '#828282', 
          'width': item.progress >= 100 ? '100%' : item.progress <= 30 ? 30 + '%' : item.progress + '%',
          'border-radius': item.progress >= 100 ? '12px' : ''
        }"
      >
        <div v-if="item.progress <= 100 && flag" class="flag">
          <img :src="require(`@/assets/dashboard/flag-icons/flag-${item.flag}.svg`)" alt="">
        </div>
        <span class="percent done">{{ item.in_fact }}</span>
        <div class="budget_buble"
          v-if="item.planned - item.in_fact < 0"
        >
          <div class="speech-bubble">
            {{ item.planned - item.in_fact }}
          </div>
        </div>
      </div>
      <div
        class="unfilled"
        :style="{
          'width': item.progress >= 100 ? '0%' : (100 - item.progress) + '%'
        }"
      >
        <span class="percent left"
          :style="{'border-raduis': item.diff < 0 ? '12px' : ''}"
        >{{ 0 > (item.diff) ? '' : item.progress >= 100 ? '' : item.diff }}</span>
      </div>
    </div>
    <div class="percent_total">
      <span class="percent indicator">
        {{ item.progress }}%
      </span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: Object,
    flag: Boolean,
  },
}
</script>