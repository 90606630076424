<template>
  <router-link
    :to="{name: 'BudgetDetailed', params: {projectId , paramType , paramTitle }}"
    replace
  >
    <div class="item__header">
      <p>{{ title }}</p>
      <div class="folder_nav_arrow">
        <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    type: String,
    title: String,
  },
  computed: {
    paramType() {
      return this.type === 'Освоение' ? 'development' : 'finance';
    },
    paramTitle() {
      return this.title === 'Общий бюджет' ? 'total' : 'annual'
    },
  }
}
</script>