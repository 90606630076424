<template>
  <div class="container__engineering__mdr layout__body">

    <!-- Header -->
    <div class="title">
      <div class="header">
        <h2>Готовность разделов</h2>
      </div>
      <div @click="toggeDatePicker">
        <Calendar :date="picker" :disabled="true"/>
      </div>
    </div>
    <!-- Skeletons -->
    <template v-if="loading">
      <div
        v-for="n in 8"
        :key="n"
      >
        <div class="engineering__skeletons-header">
        <v-skeleton-loader
          type="heading"
        ></v-skeleton-loader>
        </div>
        <div class="engineering__skeletons-body">
          <v-skeleton-loader
          type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
          type="sentences"
          ></v-skeleton-loader>
          <v-skeleton-loader
          type="heading"
          ></v-skeleton-loader>
        </div>
        </div>
    </template>
    <!-- Main comtent loaded -->
    <template v-if="!loading">
      
      <div
        v-for="(item, i) in data"
        :key="i"
      >
        <EngineeringProgress :item="item"/>
      </div>
      <div class="flags__legends">
        <div class="flags">
          <div class="flag">
            <div>
              <img src="@/assets/dashboard/flag-icons/flag-green.svg" alt="">
            </div>
            <p>В плане</p>
          </div>
          <div class="flag">
            <div>
              <img src="@/assets/dashboard/flag-icons/flag-yellow.svg" alt="">
            </div>
            <p>Отставание не более 20%</p>
          </div>
          <div class="flag">
            <div>
              <img src="@/assets/dashboard/flag-icons/flag-red.svg" alt="">
            </div>
            <p>Отставание более 20%</p>
          </div>
        </div>
        <hr>
        <div class="legends">
          <div class="legend">
            <img src="@/assets/dashboard/legends-icons/purple.svg" alt="">
            <p>У проектировщика</p>
          </div>
          <div class="legend">
            <img src="@/assets/dashboard/legends-icons/green.svg" alt="">
            <p>На ревизии</p>
          </div>
          <div class="legend">
            <img src="@/assets/dashboard/legends-icons/blue.svg" alt="">
            <p>Принято заказчиком</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import dashboardApi from '@/helpers/dashboardApi';
import Calendar from '../../components/Dashboard/Calendar.vue';
import EngineeringProgress from '@/components/Dashboard/EngineeringProgress.vue';
import { backNavigateQueue } from "@/helpers/app.js";
import bridge from "@/helpers/bridge";

export default {
  components: {
    Calendar,
    EngineeringProgress,
  },
  data() {
    return {
      showDatePicker: false,
      loading: true,
      projectId: this.$route.params.projectId,
      data: null,
      // picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }
  },
  computed: {
    picker() {
      return this.$store.state.cp.mdrPickerDate;
    }
  },
  async created() {
    try {
      const res = await dashboardApi(`/project/${this.projectId}/mdr/complect/${this.picker}/`)
      this.data = res.data
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    bridge.disableScreenCapture();
  },
  methods: {
    handleBackNavigation() {
      this.$router.replace({
        name: 'Engineering'
      })
    },
    toggeDatePicker() {
      this.showDatePicker = !this.showDatePicker
    },
    closeDatePicker(i) {
      this.showDatePicker = i
    },
  },
  beforeDestroy() {
    bridge.enableScreenCapture();
  },
}
</script>

<style lang="scss" scoped>
h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.container__engineering__mdr {
  padding: 1rem;
}

.title {
  display: flex;
  justify-content: space-between;
}

/* flags and legends */
.flags__legends {
  display: flex;
  border-top: 2px solid rgba(221, 225, 229, 1);
  padding: 1rem 0;
  /* grid-template-columns: 1fr 1fr; */
}
.flags__legends p {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.flags,
.legends {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.flag,
.legend {
  display: flex;
  gap: .5rem;
}

.engineering__skeletons {
  &-body {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    gap: .5rem;
    margin-bottom: 1rem;

    :first-child {
      width: 100%;
    }
  }

  &-header {
    width: 200%;
    margin: 1rem 0 1rem;
  }
}
</style>