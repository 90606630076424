<template>
  <div class="schedule__empty">
    <h6>{{ message }}</h6>
  </div>
</template>

<script>
export default {
  props: {
    message: String
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/dashboard/utils';
/* empty page */
.schedule__empty {
  @include flex-center;
  color: grey;
  height: 100%;
  
  h6 {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2rem;
  }
}
</style>