<template>
  <div class="supply__container layout__body">

    <template>
      <div class="title">
        <div class="header">
          <h2>Прогресс ОТО общий</h2>
        </div>
        <div @click="toggleDatePicker">
          <Calendar :date="picker"/>
        </div>
      </div>
    </template>

    <!-- Skeleton loaders -->
    <template v-if="loading">
      <!-- total -->
      <div class="supply__container-loading-total">
        <v-skeleton-loader
        type="heading"
        ></v-skeleton-loader>
        <v-skeleton-loader
        type="heading"
        ></v-skeleton-loader>
        <v-skeleton-loader
        type="heading"
        ></v-skeleton-loader>
      </div>

      <!-- header -->
      <div class="supply__container-loading-header">
        <v-skeleton-loader
        type="heading"
        ></v-skeleton-loader>
      </div>
      
      <!-- items -->
      <div
        v-for="n in 7"
        :key="n + Math.random()"
        class="supply__container-loading-items"
      >
        <v-skeleton-loader
        type="paragraph"
        ></v-skeleton-loader>
      </div>
    </template>

    <!-- Content loaded -->
    <template v-if="!loading">
      <!-- total bar -->
      <div
        class="progress"
        v-for="{id, planned, in_fact, progress, title} in total"
        :key="id"
      >
        <div class="label">
          <p>{{title}}</p>
        </div>
        <div class="bar">
          <div class="filled">
            <p>{{ in_fact }}</p>
          </div>
          <p class="unfilled">{{ planned - in_fact }}</p>
        </div>
        <div class="percent">
          <p>{{ progress }}%</p>
        </div>
      </div>

      <div class="heading">
        <h2>Трэк ОТО</h2>
      </div>

      <!-- Groups -->
      <div
        v-for="{id, title, flag} in groups"
        :key="id"
      >
        <router-link
          :to="{name: 'SupplyFolder', params: { projectId: projectId, groupId: id }}" replace
        >
          <div class="information">
            <div class="folder">
              <img src="@/assets/dashboard/icons/Folder.svg" alt="">
            </div>
            <div class="information__label">
              <p>{{title}}</p>
            </div>
            <div class="flag" v-if="flag">
              <img src="@/assets/dashboard/flag-icons/flag-red.svg" alt="">
            </div>
            <div class="arrow">
              <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
            </div>
          </div>
        </router-link>
      </div>
      <!-- end of Groups -->

      <!-- List (items w/out groups) -->
      <div
        v-for="{ id, flag, title, progress, itb_packet, tender, signing_of_the_contract, rkd_development, manufacturing, supply, acceptance, warehousing, installation, cold_test } in list"
        :key="id"
        class="progress__element"
      >
        <div class="progress__header">
          <div class="element-title"><p>{{ title }}</p></div>
          <div class="element-flag" v-if="flag"><img src="@/assets/dashboard/supply/flag-supply-red.svg" alt=""></div>
          <div class="element-percent"><p class="progress__percent">{{progress}}%</p></div>
        </div>
        <div class="progress__bar">
          <div class="div" :class="{current: itb_packet, 'active_step': progress === 0 }"></div>
          <div class="div" :class="{current: tender, 'active_step': progress === 5 }"></div>
          <div class="div" :class="{current: signing_of_the_contract, 'active_step': progress === 10 }"></div>
          <div class="div" :class="{current: rkd_development, 'active_step': progress === 15 }"></div>
          <div class="div" :class="{current: manufacturing, 'active_step': progress === 20 }"></div>
          <div class="div" :class="{current: supply, 'active_step': progress === 70 }"></div>
          <div class="div" :class="{current: acceptance, 'active_step': progress === 80 }"></div>
          <div class="div" :class="{current: warehousing, 'active_step': progress === 85 }"></div>
          <div class="div" :class="{current: installation, 'active_step': progress === 90 }"></div>
          <div class="div" :class="{current: cold_test, 'active_step': progress === 95 }"></div>
        </div>
        <div class="steps">
          <p>Шаг 
            <span>{{ cold_test ? 10
            : installation ? 10
            : warehousing ? 9
            : acceptance ? 8
            : supply ? 7
            : manufacturing ? 6
            : rkd_development ? 5
            : signing_of_the_contract ? 4
            : tender ? 3
            : itb_packet ? 2 : 0 }}</span>
          из 
          <span>10</span>{{!cold_test ? ':' : ''}} <span>{{ 
            cold_test ? ''
            : installation ? 'Холодные испытания'
            : warehousing ? 'Монтаж'
            : acceptance ? 'Складирование' 
            : supply ? 'Приемка'
            : manufacturing ? 'Поставка'
            : rkd_development ? 'Изготовление'
            : signing_of_the_contract ? 'Разработка РКД'
            : tender ? 'Подписание договора'
            : itb_packet ? 'Проведение тендера' : 'Подготовка ITB пакета'
            }}</span></p> 
        </div>
      </div>

      <DatePicker
        v-if="showDatePicker"
        @closeOverlay="closeDatePicker($event)"
        @monthChanged="getAvailableDates($event)"
        @dateChanged="changeDatePicker($event)"
        :date="picker"
        :min="min"
        :loadingDates="loadingDates"
        :availableDates="availableDates"
      ></DatePicker>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import dashboardApi from '@/helpers/dashboardApi';
import Calendar from '../../components/Dashboard/Calendar.vue';
import { backNavigateQueue } from "@/helpers/app.js";
import DatePicker from '@/components/Dashboard/DatePicker.vue';
import bridge from "@/helpers/bridge";

export default {
  components: {
    Calendar,
    DatePicker,
  },
  data() {
    return {
      // init states
      loading: true,
      loadingDates: false,
      projectId: this.$route.params.projectId,

      // data
      total: null,
      groups: null,
      list: null,

      // dates
      availableDates: null,

      // toggles
      showDatePicker: false,

      // axios cancel token
      getDatesCancelToken: undefined,
    }
  },
  computed: {
    picker() {
      return this.$store.getters['cp/otoPickerDate'];
    },
    min() {
      return this.$store.getters['cp/otoMinDate'];
    },
  },
  created() {
    this.$store.dispatch('cp/setOtoMinDate', this.projectId);
    this.picker && this.getDataByDate(this.picker);
  },
  methods: {
    handleBackNavigation() {
      this.$router.replace({
        name: 'ProjectsPage'
      })
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
    closeDatePicker(e) {
      this.showDatePicker = e;
    },
    async getAvailableDates(month) {
      try {
        this.loadingDates = true;

        if (typeof this.getDatesCancelToken != typeof undefined) {
          this.getDatesCancelToken.cancel('Operation canceled due to new request.');
        }

        this.getDatesCancelToken = axios.CancelToken.source();

        const res = await dashboardApi(`/project/${this.projectId}/oto/date/${month}/`, {
          cancelToken: this.getDatesCancelToken.token
        });
        this.availableDates = res.data;

        this.loadingDates = false;
      } catch (error) {
        console.log(error);
      }
    },
    changeDatePicker(event) {
      this.$store.dispatch('cp/changeOtoDatePicker', event);
    },
    async getDataByDate(date) {
      this.loading = true;
      try {
        const getTotal = await dashboardApi(`/project/${this.projectId}/oto/total/${date}/`);
        this.total = getTotal.data;

        const getGroups = await dashboardApi(`/project/${this.projectId}/oto/group/${date}/`);
        this.groups = getGroups.data;

        const getList = await dashboardApi(`/project/${this.projectId}/oto/${date}/`);
        this.list = getList.data
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    bridge.disableScreenCapture();
  },
  watch: {
    picker() {
      this.getDataByDate(this.picker);
    }
  },
  beforeDestroy() {
    bridge.enableScreenCapture();
  },
}
</script>

<style lang="scss" scoped>

.supply__container {
  &-loading-total {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    gap: .5rem;
    margin: 1rem;
    :first-child {
      width: 100%;
    }
  }

  /* heading */
  &-loading-header {
    margin-left: 40%;
    width: 50%;
  }

  /* items */
  &-loading-items {
    margin: 1rem;
  }
}

p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
}

.progress__percent {
  color: #4F4F4F;
  font-size: .875rem;
}

.supply__container {
  position: relative;
}

.title {
  display: flex;
  justify-content: space-between;
  margin: .8rem 1rem .25rem;
}

.header > h2 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.progress {
  display: flex;
  justify-content: space-between;
  margin: 1.3rem 1rem 1rem;
  position: relative;
}

.progress p {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.label,
.percent {
  border-radius: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.02em;
}

.label,
.percent {
  background: #828282;
}
.label > p,
.percent > p {
  margin: 2px 11.5px;
  width: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.bar {
  display: flex;
  flex-basis: 100%;
  margin: 0 .5rem;
  border: 1px solid #DDE1E5;
  border-radius: 12px;
  position: relative;
}

.filled {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #828282;
  border-radius: 12px 0px 0px 12px;
  width: 30%;
  text-align: center;
  color: #fff;
  position: relative;
}

/* .filled::after {
  content: '+3';
  background: url('../../assets/dashboard/flag-icons/bubble.svg') no-repeat center center/cover;
  position: absolute;
  right: -15px;
  top: -1.7rem;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 30px;
  height: 25px;
  text-align: center;
} */

.unfilled {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70%;
  border-radius: 0px 12px 12px 0px;
}

.heading > h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.information {
  display: flex;
  align-items: center;
  padding: .5rem 12px;
  position: relative;
  border-bottom: 1px solid #DDE1E5;
}

.arrow {
  position: absolute;
  right: 12px;
}

.information__label {
  margin: .25rem;
}

.progress__element {
  position: relative;
  border-bottom: 1px solid #DDE1E5;
}

.progress__header {
  display: flex;
  margin: .5rem .5rem 0;
}

.element-percent {
  position: absolute;
  right: .5rem;
}

.progress__bar {
  display: flex;
  gap: .25rem;
  margin: .625rem .5rem;
}

.progress__bar > div {
  width: 14px;
  height: 14px;
  opacity: 0.8;
  border-radius: 2px;
  background: #E0E0E0;
}
/* .progress__bar div:nth-child(-n + 5) {
  background: #56CCF2;
} */

.progress__bar div:nth-child(5) {
  flex-grow: 1;
}

.active_step {
  background: #90BC34 !important;
}

.current {
  background: #56CCF2 !important;
}

.progress__bar div:nth-last-child(-n + 4) {
  background: #E0E0E0;
}

.progress__bar div:nth-child(6) {
  width: 31px;
  height: 14px;
  background: #E0E0E0;
}

.steps {
  margin: 0 .5rem .5rem;
}

.steps > p {
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.25rem;
  color: #8E8E93;
}
</style>