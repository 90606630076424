var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"passport__container layout__body"},[(_vm.loading)?[_c('CPLoading')]:_vm._e(),(!_vm.loading)?[_c('div',{staticClass:"dash__watermark",style:({backgroundImage: _vm.watermark})},[_c('h2',[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('hr'),_c('div',{staticClass:"passport-element"},[_c('h5',[_vm._v(" Стадия ")]),_c('div',{staticClass:"stages"},_vm._l((_vm.stages),function(ref,i){
var name = ref.name;
var isActive = ref.isActive;
return _c('div',{key:i,staticClass:"stage",class:{active: _vm.activeStage[0] === name}},[_vm._v(" "+_vm._s(name)+" ")])}),0)]),_c('div',{staticClass:"passport-element"},[_c('h5',[_vm._v(" Руководитель проекта ")]),_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.data.project_manager)+" ")])]),_c('div',{staticClass:"passport-element"},[_c('h5',[_vm._v(" Куратор проекта ")]),_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.data.project_curator)+" ")])]),_c('div',{staticClass:"passport-element"},[_c('h5',[_vm._v(" Бизнес-единица ")]),_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.data.be)+" ")])]),_c('div',{staticClass:"passport-element"},[_c('h5',[_vm._v(" текущие Показатели проекта ")]),_c('div',{staticClass:"table"},[_c('div',{staticClass:"grid__table"},[_c('div',{staticClass:"blank"},[_vm._v(" blank ")]),_c('div',{staticClass:"grid__header"},[_vm._v(" Утверждено ")]),_c('div',{staticClass:"grid__header"},[_vm._v(" Прогноз ")]),_c('div',{staticClass:"grid__header"},[_vm._v(" Отклонение ")]),_c('div',{staticClass:"grid__header"},[_vm._v(" Бюджет ")]),_c('div',[_vm._v(" $ "+_vm._s(_vm.data.current_indicators.budget_approved)+" млн ")]),_c('div',[_vm._v(" $ "+_vm._s(_vm.data.current_indicators.budget_predicted)+" млн ")]),_c('div',{style:({
                'color': _vm.data.current_indicators.flag_budget == 'red' ? 'red' : 'green'
              })},[_vm._v(" $ "+_vm._s(_vm.data.current_indicators.budget_diff)+" млн ")]),_c('div',{staticClass:"grid__header"},[_vm._v(" Срок ")]),_c('div',[_vm._v(" "+_vm._s(_vm.data.current_indicators.date_approved)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.data.current_indicators.date_predicted)+" ")]),_c('div',{style:({
                color: _vm.data.current_indicators.flag_date == 'red' ? 'red' : 'green'
              })},[_vm._v(" "+_vm._s(_vm.data.current_indicators.date_diff)+" дн. ")])])]),_c('button',{class:{buttonPressed: _vm.buttonPressed},on:{"click":function($event){_vm.buttonPressed = !_vm.buttonPressed}}},[_vm._v(" Базовые Показатели проекта ")]),(_vm.buttonPressed)?_c('div',[_c('div',{staticClass:"basic__grid__table"},[_c('div',{staticClass:"blank"},[_vm._v(" blank ")]),_c('div',{staticClass:"grid__header"},[_vm._v(" Бюджет ")]),_c('div',{staticClass:"grid__header"},[_vm._v(" Срок завершения ")]),_c('div',{staticClass:"grid__header"},[_vm._v(" FEL1 ")]),_c('div',[_vm._v(" $ "+_vm._s(_vm.data.baselines[0].budget)+" млн ")]),_c('div',[_vm._v(" "+_vm._s(_vm.data.baselines[0].date === null ? ' - ' : _vm.data.baselines[0].date)+" ")]),_c('div',{staticClass:"grid__header"},[_vm._v(" FEL2 ")]),_c('div',[_vm._v(" $ "+_vm._s(_vm.data.baselines[1].budget)+" млн ")]),_c('div',[_vm._v(" "+_vm._s(_vm.data.baselines[1].date === null ? ' - ' : _vm.data.baselines[1].date)+" ")]),_c('div',{staticClass:"grid__header"},[_vm._v(" FEL3 ")]),_c('div',[_vm._v(" $ "+_vm._s(_vm.data.baselines[2].budget)+" млн ")]),_c('div',[_vm._v(" "+_vm._s(_vm.data.baselines[2].date === null ? ' - ' : _vm.data.baselines[2].date)+" ")]),_c('div',{staticClass:"grid__header"},[_vm._v(" EXEC ")]),_c('div',[_vm._v(" $ "+_vm._s(_vm.data.baselines[3].budget)+" млн ")]),_c('div',[_vm._v(" "+_vm._s(_vm.data.baselines[3].date === null ? ' - ' : _vm.data.baselines[3].date)+" ")])])]):_vm._e()]),_c('div',{staticClass:"passport-element"},[_c('h5',[_vm._v(" Бизнес-кейс ")]),_vm._l((_vm.data.business_cases),function(ref){
              var title = ref.title;
              var id = ref.id;
return _c('p',{key:id,staticClass:"description"},[_vm._v(" "+_vm._s(title)+" ")])})],2),_c('div',{staticClass:"passport-element"},[_c('h5',[_vm._v(" Цели и стратегия реализации ")]),_vm._l((_vm.data.goals),function(ref){
              var id = ref.id;
              var title = ref.title;
return _c('p',{key:id,staticClass:"description"},[_vm._v(" "+_vm._s(title)+" ")])})],2)])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }