<template>
  <div class="layout__body">
    <iframe
      height="100%"
      width="100%"
      allowfullscreen="true"
      :src="url.link"
    >
    </iframe>
  </div>
</template>

<script>
import { backNavigateQueue } from "@/helpers/app.js";
import dashboardApi from "@/helpers/dashboardApi.js"

export default {
  data() {
    return {
      url: null,
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.projectId);
    }
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    this.getURL();
  },
  methods: {
    handleBackNavigation() {
      this.$router.replace({
        name: 'ProjectsPage'
      })
    },
    async getURL() {
      try {
        const res = await dashboardApi(`/project/${this.id}/video/`);
        this.url = res.data.urls[0];
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style scoped>

</style>