<template>
  <div class="program-container layout__body">

    <!-- program info header -->
    <div class="program-info">
      <div class="program-name">

        <!-- Name skeleton -->
        <v-skeleton-loader
          type="heading"
          v-if="!projectInfo"
        ></v-skeleton-loader>

        <!-- Name loaded -->
        <h3 v-if="projectInfo">
          {{ this.projectInfo.project_manager }}
        </h3>
        <p>
          Руководитель проекта
        </p>
      </div>
      
      <div class="program-dates">
        
        <!-- Dates skeleton -->
        <div class="project-info-skeleton" v-if="!projectInfo">
          <v-skeleton-loader
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-two-line"
          ></v-skeleton-loader>
        </div>
        
        <!-- Dates loaded -->
        <div v-if="projectInfo">
          <p>{{ this.projectInfo.start_date }}</p>
          <p class="program-dates-label">Дата начала</p>
        </div>
        <div v-if="projectInfo">
          <p>{{ this.projectInfo.end_date }}</p>
          <p class="program-dates-label">Плановая дата окончания</p>
        </div>
      </div>
    </div>
    <!-- End of program info header -->

    <!-- Folders begins -->
    <div class="folders">
      <!-- 1. Passport -->
      <router-link
          :to="{name: 'Passport', params: {projectId: this.projectId}}" replace
          >
          <div class="folder" @click="setTitle(menuItems.passport)">
          <div class="folder-icon">
              <img src="@/assets/dashboard/project-page-icons/projects-passport.svg" alt="" />
            </div>
            <div class="folder-label">
              <p class="folder-label-text">
                {{ menuItems.passport }}
              </p>
              <p class="folder-description">
                Основная информация
              </p>
            </div>
            <div class="nav-arrow-right">
              <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
            </div>
        </div>
      </router-link>
      <!-- 2. Video -->
      <router-link
        :to="{ name: 'VideoHikvision', params: {projectId: this.projectId}}"
        replace
      >
        <div class="folder" @click="setTitle(menuItems.video)">
          <div class="folder-icon">
            <img src="@/assets/dashboard/project-page-icons/construction-video.svg" alt="" />
          </div>
          <div class="folder-label">
            <p class="folder-label-text">
              {{ this.menuItems.video }}
            </p>
            <p class="folder-description">
              {{ 'Просмотр объектов в реальном времени' }}
            </p>
          </div>
          <div class="nav-arrow-right">
            <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
          </div>
        </div>
      </router-link>
      <!-- 3. Staff -->
      <router-link
        :to="{name: 'Staff', params: {projectId: this.projectId}}" replace
      >
        <div class="folder" @click="setTitle(menuItems.staff)">
          <div class="folder-icon">
            <img src="@/assets/dashboard/project-page-icons/personal.svg" alt="" />
          </div>
          <div class="folder-label">
            <p class="folder-label-text">
              {{ this.menuItems.staff }}
            </p>
            <p class="folder-description">
              Список сотрудников и вакансии
            </p>
          </div>
          <div class="nav-arrow-right">
            <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
          </div>
        </div>
      </router-link>
      <!-- 4. Budget -->
      <router-link
        :to="{name: 'Budget', params: {projectId: this.projectId}}" replace
      >
        <div class="folder" @click="setTitle(menuItems.budget)">
          <div class="folder-icon">
            <img src="@/assets/dashboard/project-page-icons/wallet.svg" alt="" />
          </div>
          <div class="folder-label">
            <p class="folder-label-text">
              {{ this.menuItems.budget }}
            </p>
            <p class="folder-description">
              Остаток и перечень средств
            </p>
          </div>
          <div class="nav-arrow-right">
            <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
          </div>
        </div>
      </router-link>
      <!-- 5. Engineering -->
      <router-link
        :to="{name: 'Engineering', params: {projectId: this.projectId}}" replace
      >
        <div class="folder">
          <div class="folder-icon">
            <img src="@/assets/dashboard/project-page-icons/injiring.svg" alt="" />
          </div>
          <div class="folder-label">
            <p class="folder-label-text">
              {{ this.menuItems.engineering }}
            </p>
            <p class="folder-description">
              Работы по земляным потокам
            </p>
          </div>
          <div class="nav-arrow-right">
            <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
          </div>
        </div>
      </router-link>
      <!-- 6. Supply -->
      <router-link
        :to="{name: 'Supply', params: {projectId: this.projectId}}" replace
      >
        <div class="folder" @click="setTitle(menuItems.supply)">
          <div class="folder-icon">
            <img src="@/assets/dashboard/project-page-icons/snabjenie.svg" alt="" />
          </div>
          <div class="folder-label">
            <p class="folder-label-text">
              {{ this.menuItems.supply }}
            </p>
            <p class="folder-description">
              Данные о вашем снабжении
            </p>
          </div>
          <div class="nav-arrow-right">
            <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
          </div>
        </div>
      </router-link>
      <!-- 7. Building -->
      <router-link
        :to="{name: 'BuildingBE', params: { projectId: this.projectId }}" replace
      >
        <div class="folder" @click="setTitle(menuItems.building)" >
          <div class="folder-icon">
            <img src="@/assets/dashboard/project-page-icons/construction.svg" alt="" />
          </div>
          <div class="folder-label">
            <p class="folder-label-text">
              {{ this.menuItems.building }}
            </p>
            <p class="folder-description">
              Данные о вашем объекте
            </p>
          </div>
          <div class="nav-arrow-right">
            <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
          </div>
        </div>
      </router-link>
      <!-- 8. Schedule -->
      <router-link
        :to="{name: 'Schedule', params: { projectId: this.projectId}}" replace
      >
        <div class="folder" @click="setTitle(menuItems.schedule)">
          <div class="folder-icon">
            <img src="@/assets/dashboard/project-page-icons/calendar.svg" alt="" />
          </div>
          <div class="folder-label">
            <p class="folder-label-text">
              {{ this.menuItems.schedule }}
            </p>
            <p class="folder-description">
              Информация о расписании
            </p>
          </div>
          <div class="nav-arrow-right">
            <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
          </div>
        </div>
      </router-link>
    </div>
    <!-- End of folders -->
  </div>
</template>


<script>
import dashboardApi from "@/helpers/dashboardApi.js"
import { backNavigateQueue } from "@/helpers/app.js";
import bridge from "@/helpers/bridge";

export default {
  data() {
    return {
      projectId: Number(this.$route.params.projectId),
      projectInfo: null,
      menuItems: {
        passport: 'Паспорт проекта',
        video: 'Видео строительства',
        staff: 'Персонал',
        budget: 'Бюджетные показатели',
        engineering: 'Инжинириг',
        supply: 'Снабжение',
        building: 'Строительство',
        schedule: 'ПНР и ввод в эксплуатацию',
      }
    }
  },
  async created() {
    const res = await dashboardApi(`/project/${this.projectId}/`)
    this.projectInfo = res.data
  },
  methods: {
    handleBackNavigation() {
      this.$router.replace({
        name: 'Projects', params: {divisionId: this.divisionId}
      })
    },
    setTitle(title) {
      this.$store.dispatch('layout/setHeaderTitle', title);
    }
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    // this.$store.dispatch('layout/setHeaderTitle', this.projectInfo?.title);
    bridge.enableScreenCapture();
  },
  computed: {
    divisionId() {
      return this.projectInfo.division_id
    },
    title() {
      return this.projectInfo?.title
    }
  },
  watch: {
    title() {
      this.setTitle(this.title);
    }
  }
}
</script>

<style scoped>
.layout__body {
  padding-top: 0;
}
.folders {
  margin-top: .5rem;
  border-radius: .75rem;
  padding-top: 9.5rem;
}
/* project information styles */
.program-container {
  background-color: #F9F9F9;
  position: relative;
}

.program-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0 0 0.75rem 0.75rem;
  background-color: #fff;
  padding: 1rem;
  position: fixed;
  z-index: 1;
}
.program-info h3 {
  font-weight: 600;
  font-size: 1.75rem;
  color: #101828;
}

.program-info p {
  text-align: left;
}

.program-name h3{
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.02em;
}

.program-name p {
  color: #8E8E93;
  padding: 0.25rem 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.program-budget, .program-dates {
  display: flex;
  flex-direction: row;
  gap: 25%;
  padding-top: 1rem;
}

.program-dates p {
  font-weight: 500;
  font-size: .875rem;
  line-height: 1.25rem;
  font-style: normal;
}

.program-dates {
  padding-top: 1rem;
}

.program-dates-label {
  color: #8E8E93
}

/* list styles */
.folder {
  display: flex;
  flex-direction: row;
  min-height: 4.25rem;
  background-color: #fff;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 1px;
}

.nav-arrow-right {
  position: absolute;
  right: 1rem;
}

.folder-label {
  margin: 0 1rem;
}

.folder-label-text {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.folder-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8E8E93
}

.project-info-skeleton {
  display: flex;
  padding-top: 1rem;
  height: 4.5rem;
  width: 30rem;
}

.project-info-skeleton > * {
  width: 15rem;
}
</style>