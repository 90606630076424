<template>
  <div class="dashboard__container layout__body">
    <CPLoading v-if="loading"/>
    <!-- <EmptyDash v-if="error" message="Дивизионы не найдены"/> -->
    <DashMessage
      v-if="error"
      hasXClose
      message="Дивизионы не найдены"
      hasBtn
      @clear="handleBackNavigation"
      @btn-pressed="handleBackNavigation"
    ></DashMessage>
    <div
      class="dashboard__main__menu"
      v-if="!loading"
    >
      <div
        v-for="{id, title, icon } in divisions"
        :key="id"
        class="menu-items"
      >
        <router-link
          :to="{name: 'Projects', params: {divisionId: id}}"
          replace
          class="menu-items"
        >
          <div class="icon__divisions">
            <img :src="require(`@/assets/dashboard/icons/${icon}.svg`)" alt="">
          </div>
          <div class="dashboard-break-word">
            <p class="dashboard__title">{{ title }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>


<script>
import dashboardApi from '@/helpers/dashboardApi';
import { backNavigateQueue } from "@/helpers/app.js";
import CPLoading from '@/components/Dashboard/CPLoading.vue'
// import EmptyDash from '@/components/Dashboard/EmptyDash.vue'
import DashMessage from '@/components/Dashboard/DashMessage.vue'
import bridge from "@/helpers/bridge";

export default {
  components: {
    CPLoading,
    // EmptyDash,
    DashMessage
  },
  data() {
    return {
      divisions: null,
      loading: true,
      error: false,
    }
  },
  async created() {
    try {
      const res = await dashboardApi('/division/list')
      this.divisions = res.data
      console.log("res: " + res)
      // this.loading = false;
    } catch (error) {
      if (error.response) {
        // ✅ log status code here
        console.log(error.response.status);
      }
      this.error = true;
      this.$store.dispatch('layout/alert', error)
    } finally {
      this.loading = false;
    }
  },
  methods: {
    handleBackNavigation() {
      this.$router.replace({
        name: 'MainPage'
      })
    },
  },
  mounted() {
    this.$store.dispatch('layout/setHeaderTitle', 'Дивизионы');
    backNavigateQueue.set(this.handleBackNavigation);
    bridge.enableScreenCapture();
  }
}
</script>

<style scoped>
</style>