<template>
  <div class="passport__container layout__body">
    <!-- Loading -->
    <template v-if="loading">
      <CPLoading />
    </template>

    <!-- Loaded -->
    <template v-if="!loading">
      <div :style="{backgroundImage: watermark}" class="dash__watermark">
      <h2>
        {{ data.title }}
      </h2>
        <hr>

        <!-- Стадия -->
        <div class="passport-element" >
          <h5>
            Стадия
          </h5>
          <div class="stages">
            <div
              v-for="({name, isActive}, i) in stages"
              :key="i"
              class="stage"
              :class="{active: activeStage[0] === name}"
            >
              {{ name }}
            </div>
          </div>
        </div>

        <!-- Руководитель проекта -->
        <div class="passport-element">
          <h5>
            Руководитель проекта
          </h5>
          <p class="name">
            {{ data.project_manager}}
          </p>
        </div>

        <!-- Куратор проекта -->
        <div class="passport-element">
          <h5>
            Куратор проекта
          </h5>
          <p class="name">
            {{ data.project_curator }}
          </p>
        </div>

        <!-- Бизнес-единица -->
        <div class="passport-element">
          <h5>
            Бизнес-единица
          </h5>
          <p class="name">
            {{ data.be }}
          </p>
        </div>

        <!-- текущие Показатели проекта -->
        <div class="passport-element">
          <h5>
            текущие Показатели проекта
          </h5>

          <!-- table of current indicators -->
          <div class="table">
            <div class="grid__table">
              <div class="blank">
                blank
              </div>
              <div class="grid__header">
                Утверждено
              </div>
              <div class="grid__header">
                Прогноз
              </div>
              <div class="grid__header">
                Отклонение
              </div>
              <div class="grid__header">
                Бюджет
              </div>
              <div>
                $ {{ data.current_indicators.budget_approved }} млн
              </div>
              <div>
                $ {{ data.current_indicators.budget_predicted }} млн
              </div>
              <div
                :style="{
                  'color': data.current_indicators.flag_budget == 'red' ? 'red' : 'green'
                }"
              >
                $ {{ data.current_indicators.budget_diff}} млн
              </div>
              <div class="grid__header">
                Срок
              </div>
              <div>
                {{ data.current_indicators.date_approved }} 
              </div>
              <div>
                {{ data.current_indicators.date_predicted }}
              </div>
              <div
                :style="{
                  color: data.current_indicators.flag_date == 'red' ? 'red' : 'green'
                }"
              >
                {{ data.current_indicators.date_diff }} дн.
              </div>
            </div>
          </div>
          <button
            @click="buttonPressed = !buttonPressed"
            :class="{buttonPressed: buttonPressed}"
          >
            Базовые Показатели проекта
          </button>

          <!-- basic project indicators -->
          <div
            v-if="buttonPressed"
          >
            <div class="basic__grid__table">
              <div class="blank">
                blank
              </div>
              <div class="grid__header">
                Бюджет
              </div>
              <div class="grid__header">
                Срок завершения
              </div>
              <div class="grid__header">
                FEL1
              </div>
              <div>
                $ {{ data.baselines[0].budget}} млн
              </div>
              <div>
                {{ data.baselines[0].date === null ? ' - ' : data.baselines[0].date }}
              </div>
              <div class="grid__header">
                FEL2
              </div>
              <div>
                $ {{ data.baselines[1].budget}} млн
              </div>
              <div>
                {{ data.baselines[1].date === null ? ' - ' : data.baselines[1].date }}
              </div>
              <div class="grid__header">
                FEL3
              </div>
              <div>
                $ {{ data.baselines[2].budget}} млн
              </div>
              <div>
                {{ data.baselines[2].date === null ? ' - ' : data.baselines[2].date }}
              </div>
              <div class="grid__header">
                EXEC
              </div>
              <div>
                $ {{ data.baselines[3].budget}} млн
              </div>
              <div>
                {{ data.baselines[3].date === null ? ' - ' : data.baselines[3].date }}
              </div>
            </div>
          </div>
        </div>

        <!-- Бизнес-кейс -->
        <div class="passport-element">
          <h5>
            Бизнес-кейс
          </h5>
          <p
            class="description"
            v-for="{title, id} in data.business_cases"
            :key="id"
          >
            {{ title }}
          </p>
        </div>
        <!-- Цели и стратегия реализации -->
        <div class="passport-element">
          <h5>
            Цели и стратегия реализации
          </h5>
          <p
            class="description"
            v-for="{ id, title } in data.goals"
            :key="id"
          >
            {{ title }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CPLoading from "@/components/Dashboard/CPLoading.vue";
import { backNavigateQueue } from "@/helpers/app.js";
import dashboardApi from '@/helpers/dashboardApi';
import bridge from "@/helpers/bridge";
import { mapState } from "vuex";

export default {
  components: {
    CPLoading,
  },
  data() {
    return {
      stages: [
        {
          name: 'FEL1',
          isActive: false
        },
        {
          name: 'FEL2',
          isActive: false
        },
        {
          name: 'FEL3',
          isActive: false
        },
        {
          name: 'EXEC',
          isActive: false
        }
      ],
      buttonPressed: false,
      loading: true,
      error: false,
      // async data
      data: null,
      projectId: this.$route.params.projectId,
      activeStage: null,
      backImage: 'fefe',
      image: null,
    }
  },
  async created() {
    try {
      const res = await dashboardApi(`project/${this.projectId}/passport/`);
      this.data = res.data
      this.activeStage = res.data.stage
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    bridge.disableScreenCapture();
    !this.watermark && this.$store.dispatch('cp/watermark', this.payload);
  },
  methods: {
    handleBackNavigation() {
      this.$router.replace({
        name: 'ProjectsPage'
      })
    },
  },
  beforeDestroy() {
    bridge.enableScreenCapture();
  },
  computed: {
    ...mapState("user", [
      "aituMeData"
    ]),
    payload() {
      return {
        userid: this.aituMeData.id
      }
    },
    watermark() {
      return this.$store.getters['cp/watermark'];
    },
  }
}
</script>

<style scoped>
.grid__table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin: 1rem 0 1rem;
}

.grid__table div,
.basic__grid__table div {
  display: flex;
  font-weight: 400;
  font-size: .865rem;
  color: #101828;
}

.grid__table div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grid__header {
  font-weight: 600 !important;
  font-size: .865rem;
  color: #101828;
}
.basic__grid__table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 1rem 0 1rem;
}

.basic__grid__table div {
  align-items: center;
}

.grid-col-1,
.grid-col-2,
.grid-col-3,
.grid-col-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  
}

.grid-col-2,
.grid-col-3,
.grid-col-4 {
  font-weight: 400;
  font-size: .875rem;
}

.basic-grid-col-2,
.basic-grid-col-3,
.basic-grid-col-4 {
  font-weight: 400;
  font-size: .875rem;
}

.blank {
  color: white !important;
}
.passport-element {
  padding: 1rem 0 .25rem;
}
.passport-element .name {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.passport-element .description {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: .5rem;
}
.passport__container {
  padding: .5rem 1.5rem;
}

h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 2rem;
  /* padding: .5rem 1.5rem 1rem; */
}

h5 {
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  color: #8E8E93;
}

hr {
  margin-top: 1rem;
  height: 0px;
  border: 0.5px solid #DDE1E5;
}

.stages {
  display: flex;
  flex-direction: row;
  gap: .625rem;
}

.stage {
  background-color: #F2F2F2;
  height: 1.5rem;
  border-radius: .375rem;
  font-style: normal;
  font-weight: 400;
  font-size: .875rem;
  color: #101828;
  line-height: 1.25rem;
  padding: .125rem 1rem;
}

.active {
  background: rgba(144, 188, 52, 0.6);
}

button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 4px;
  border: 1px solid #898A8D;
  border-radius: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
  text-transform: uppercase;
  width: 100%;
}

.buttonPressed {
  background-color: #F2F2F2;
  border: 1px solid #898A8D;
  border-radius: 12px;
}

.passport__container {
  background-color: #fff;
}

.watermarkTest {
  border: 1px solid red;
  height: 300px;
  width: 100%;
}

</style>