<template>
  <div class="staff__container layout__body">

    <!-- Stages -->
    <div class="stages" v-if="!stagesLoading">
      <div
        v-for="{id, title} in stages"
        :key="id"
        class="stage"
        :class="{active: activeTab === id}"
        @click="getStaffByStageId(id); activeTab = id"
      >
        {{ title }}
      </div>

      <div class="calendar__position" @click="toggeDatePicker">
        <Calendar :date="picker" />
      </div>

      <!-- Date picker component -->
      <DatePicker 
        v-if="showDatePicker"
        :date="picker"
        v-on:closeOverlay="closeDatePicker($event)"
        v-on:dateChanged="setCurrentDate($event)"
        v-on:monthChanged="getAvailableDates($event)"
        :min="minimalMonth"
        :availableDates="availableDates"
        :loadingDates="loadingDates"
      />
      <!-- End of Date picker component -->

    </div>

    <div class="stages-loading" v-if="stagesLoading">
      <v-skeleton-loader
        type="text"
      ></v-skeleton-loader>
    </div>

    <!-- EmployeeInfo Overlay component -->
    <EmployeeInfo v-if="showInfo" :pickedPerson="pickedPerson" v-on:closeEmployeeOverlay="closeInfoOverlay($event)"/>
    
    <!-- Total -->
    <div v-if="!totalCountLoading">
      <div class="total-table"
      v-for="{ id, in_fact, planned } in count.filter(item => item.stage === activeTab)"
      :key="id"
      >
        <div>
          <p v-if="!totalCountLoading">
            {{ planned }}
          </p>
        </div>
        <div>
          <p>{{ in_fact }}</p>
        </div>
      </div>
    </div>

    <div class="total-loader" v-if="totalCountLoading">
      <v-skeleton-loader
        type="text"
      ></v-skeleton-loader>
    </div>

    <!-- Table -->
    <div class="table-body" v-if="!staffLoading">
      <div
        v-for="{id, employee_name, position, email} in staff"
        :key="id"
        class="table-row"
      >
        <p
          :class="{'staff-active': employee_name}"
        >{{ position }}</p>
        <div>
          <div
          v-for="(employee, i) in employee_name"
          :key="i"
          >
            <p
            :class="{'tdb': !employee_name}" @click="callOverlay([email[i], position])"
            >
              {{ !employee_name.length ? "TBD" : employee }}
            </p>
          </div>
          <div
            v-if="!employee_name.length"
          >
            <p
            :class="{'tdb': !employee_name}"
            class="tdb"
            >
              {{ "TBD" }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="staff-loader" v-if="staffLoading">
      <v-skeleton-loader
        type="table-cell@6"
      ></v-skeleton-loader>
      <v-skeleton-loader
        type="table-cell@6"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import Calendar from '@/components/Dashboard/Calendar.vue'
import EmployeeInfo from '../../components/Dashboard/EmployeeInfo.vue';
import { backNavigateQueue } from "@/helpers/app.js";
import DatePicker from '@/components/Dashboard/DatePicker.vue';
import dashboardApi from '@/helpers/dashboardApi';
import bridge from "@/helpers/bridge";

export default {
  components: {
    Calendar,
    EmployeeInfo,
    DatePicker,
  },
  data() {
    return {
      /* 
        main data
      */

      projectId: this.$route.params.projectId,
      activeTab: null,
      showInfo: false,

      stages: null,
      count: null,
      staff: null,

      /*
        Datepicker component
      */

      picker: null,
      availableDates: null,
      min: null,
      loadingDates: false,
      
      /*
        EmployeeInfo overlay component
      */

      pickedPerson: null,
      showDatePicker: false,

      /*
        skeletons
      */

      stagesLoading: true,
      totalCountLoading: true,
      staffLoading: true,
    }
  },
  async created() {
    // get stages
    try {
      const getStages = await dashboardApi(`/project/${this.projectId}/staff/stages/list/`);
      this.stages = getStages.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.stagesLoading = false;
    }

    // get total count
    try {
      const getTotal = await dashboardApi(`/project/${this.projectId}/staff/count/`);
      this.count = getTotal.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.totalCountLoading = false;
    }
  
    // get picker date and min date
    const minmax = await dashboardApi(`/project/${this.projectId}/staff/date/minmax/`);
    this.picker = minmax.data.max;
    this.min = minmax.data.min;

    // get availableDates for pickedMonth
    const availableDates = await dashboardApi(`/project/${this.projectId}/staff/date/${this.pickedMonth}/list/`);
    this.availableDates = availableDates.data;

    // get list of staff without date
    try {
      const staffOfStage = await dashboardApi(`/project/${this.projectId}/staff/stages/${this.activeStage}/list/`);
      this.staff = staffOfStage.data;
      this.activeTab = this.activeStage;
    } catch (error) {
      console.log(error);
    } finally {
      this.staffLoading = false;
    }
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    bridge.disableScreenCapture();
  },
  methods: {
    /* 
      date picker methods
    */

      // get stages by date
      async getStagesByDate(date) {
        try {
          this.stagesLoading = true;
          this.totalCountLoading = true;
          this.staffLoading = true;
          const res = await dashboardApi(`/project/${this.projectId}/staff/stages/${date}/`)
          this.stages = res.data
        } catch (error) {
          console.log(error);
        } finally {
          console.log('call stages by date');
          this.stagesLoading = false;
        }
      },

      // get total count by date
      async getCountByDate(date) {
        try {
          const res = await dashboardApi(`/project/${this.projectId}/staff/count/${date}/`)
          this.count = res.data
        } catch (error) {
          console.log(error);
        } finally {
          console.log('call total count by date');
          this.totalCountLoading = false;
        }
      },

      // get staff by date and stageId
      async getStaffByStageId(stageId) {
        try {
          // get list of staff for changedDate
          if (stageId && this.picker) {
            const res = await dashboardApi(`/project/${this.projectId}/staff/stages/${stageId}/${this.picker}/`)
            this.staff = res.data
          }
        } catch (error) {
          console.log(error)
        } finally {
          console.log('call stages by date');
          if (stageId && this.picker) {
            this.staffLoading = false;
          }
        }
      },
      toggeDatePicker() {
        this.showDatePicker = !this.showDatePicker
      },
      closeDatePicker(i) {
        this.showDatePicker = i
      },

      // change date
      setCurrentDate(i) {
        this.picker = i
        // get stages
        this.getStagesByDate(this.picker);
        // get total count
        this.getCountByDate(this.picker);
        // get staff list by stage id and date
        // this.getStaffByStageId(this.activeStage);
        // // set active tab id
        // this.activeTab = this.activeStage
      },

      // get available dates
      async getAvailableDates(month) {
        try {
          this.loadingDates = true
          const availableDates = await dashboardApi(`/project/${this.projectId}/staff/date/${month}/list/`);
          this.availableDates = availableDates.data
          this.loadingDates = false
        } catch (error) {
          console.log(error);
        }
      },
    /*
      end date picker methods
    */

    /* 
      Employee info overlay methods
    */ 
      callOverlay(i) {
        this.pickedPerson = i;
        this.showInfo = !this.showInfo
      },
      closeInfoOverlay(updatedShowInfo) {
        this.showInfo = updatedShowInfo
      },
    handleBackNavigation() {
      this.$router.replace({
        name: 'ProjectsPage'
      })
    },
  },
  computed: {
    activeStage() {
      if (this.count) {
        return this.count.filter(item => item.in_fact !== 0).slice(-1)[0].stage;
      } return null
    },
    pickedMonth() {
      return this.picker.replace(/(\d+)-(\d+)-(\d+)/, '$1-$2')
    },
    minimalMonth() {
      return this.min.replace(/(\d+)-(\d+)-(\d+)/, '$1-$2')
    }
  },
  watch: {
    activeStage(newStage) {
      if (newStage) {
        this.activeTab = this.activeStage
        this.getStaffByStageId(this.activeStage)
      }
    }
  },
  beforeDestroy() {
    bridge.enableScreenCapture();
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-skeleton-loader__text {
  height: 27px;
}

::v-deep .v-skeleton-loader__table-cell {
  width: 100%;
}

$skeleton-loader-text-height: 30px;
.staff__container {
  position: relative;
  margin: 0 1rem;
  background-color: #fff;

  /*
    loaders 
  */

  .stages-loading {
    margin: .5rem 0 1rem;
  }
  .total-loader {
    display: grid;
  }
  .staff-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    > * {
      flex: 1;
    }
  }
}

.stages {
  display: flex;
  position: relative;
  align-items: center;
  margin: .5rem 0 1rem;
  /* height: 1.5rem; */
  gap: .3rem;
}

.stage {
  background-color: #F2F2F2;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: .875rem;
  color: #101828;
  line-height: 1.25rem;
  padding: .125rem 1rem;
}

.active {
  background: rgba(144, 188, 52, 0.6);
}

.total-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.total-table div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.total-table div p {
  margin: 6px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.total-table div:nth-child(odd) {
  background: #828282;
  color: #fff;
  border: 1px solid #DDE1E5;
  border-radius: 12px 0px 0px 12px;
}
.total-table div:nth-child(even) {
  color: #4F4F4F;
  border: 1px solid #DDE1E5;
  border-radius: 0px 12px 12px 0px;
  min-width: 7.5rem;
}

.table-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .5rem;
  margin: 0 0 1.5rem;
}

.table-row p {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.25rem;
  padding: 6px 12px;
  text-align: center;
}

.table-row p:nth-child(odd) {
  border: 1px solid #898A8D;
  border-radius: 12px;
}

.staff-active {
  background: #2196F3;
  color: #fff;
}
.table-row p:nth-child(even) {
  background: #FBFCFB;
  border: 1px solid #898A8D;
  min-width: 7.5rem;
}

.tdb {
  color: #8E8E93;
  border: 1px solid red;
}

.calendar__position {
  margin-left: auto;
}
</style>