<template>
  <v-overlay class="employee__info__overlay">
    <v-container
      v-if="!loading"
      class="employee__info__container" 
      v-click-outside="closeEmployeeInfo" 
    >
      <div v-if="!notFound" class="employee__main__component">
        <div class="employee__heading">
          <img :src="image ? 'data:image/png;base64,' + image : require(`@/assets/dashboard/staff/no-profile-pic.svg`)" alt="">
          <h2>{{ info.displayName }}</h2>
          <p class="employee__title">
            {{ pickedPerson[1] }}
          </p>
        </div>
        <div class="employee__information">
          <div class="employee__information_row">
            <div class="employee__info__icon"><img src="@/assets/dashboard/staff/employee_calendar.svg" alt=""></div>
            <div class="employee__info">
              <div class="employee__info__title">Работает с</div>
              <div class="employee__info__subtitle">{{ info.extensionAttribute15 }}</div>
            </div>
          </div>
          <!-- <div class="employee__information_row">
            <div class="employee__info__icon"><img src="@/assets/dashboard/staff/employee_archive.svg" alt=""></div>
            <div class="employee__info">
              <div class="employee__info__title">Активных проектов</div>
              <div class="employee__info__subtitle">7</div>
            </div>
          </div> -->
          <div class="employee__information_row">
            <div class="employee__info__icon"><img src="@/assets/dashboard/staff/employee_phone.svg" alt=""></div>
            <div class="employee__info">
              <div class="employee__info__title">Контакты</div>
              <div class="employee__info__subtitle"><a :href="`tel:+77770001122`">+7 777 000 11 22</a></div>
              <div class="employee__info__subtitle"><a :href="`mailto:${info.mail}`">{{ info.mail }}</a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="notFound" v-if="notFound">
        <h5>Данные о сотруднике не найдены</h5>
        <div class="cancel-button__container">
        <v-btn class="cancel-button" @click="closeEmployeeInfo">Закрыть</v-btn>
      </div>
      </div>
    </v-container>
    <v-container v-click-outside="closeEmployeeInfo" class="employee__info__container" v-if="loading">
      <CPLoading class="cp-loading" />
      <div class="cancel-button__container">
        <v-btn class="cancel-button" @click="closeEmployeeInfo">Закрыть</v-btn>
      </div>
    </v-container>
  </v-overlay>
</template>

<script>
import dashboardApi from '@/helpers/dashboardApi.js';
import axios from 'axios';
import CPLoading from './CPLoading.vue';

export default {
  props: ['pickedPerson'],
  components: {
    CPLoading
  },
  data() {
    return {
      info: null,
      cancelToken: undefined,
      loading: true,
    }
  },
  methods: {
    closeEmployeeInfo() {
      this.$emit('closeEmployeeOverlay', false)
    },
  },
  async created() {
    if (this.notFound) {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    } else { 
      try {
        // cancel request if it exists
        if (typeof this.cancelToken != typeof undefined) {
          this.cancelToken.cancel('Operation canceled due to new request.');
        }
        
        // set cancel token
        this.cancelToken = axios.CancelToken.source();
        
        const res = await dashboardApi.post(`/project/2/staff/user/info/`, this.payload, {
          cancelToken: this.cancelToken.token
        })
        
        // assign responce data
        this.info = res.data
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    notFound() {
      if (!this.pickedPerson[0]) {
        return true
      } return false
    },
    payload() {
      return {'email': this.pickedPerson[0]}
    },
    image() {
      return this.info?.thumbnailPhoto.encoded
    }
  },
  beforeDestroy() {
    this.cancelToken.cancel('Operation canceled due to new request.');
  }
}
</script>

<style lang="scss" scoped>
.employee__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .25rem;

  &__overlay {
    display: flex;
    align-items: flex-end;
    position: fixed;
  }

  &__container {
    background: #fff;
    border-radius: 1rem;
    color: black;
    width: 100vw;

    .cp-loading {
      margin: 3rem 0;
    }

    .cancel-button__container {
      display: flex;
      justify-content: center;
      margin: 2rem;
    }

    .notFound {
      h5 {
        font-weight: 400;
        font-size: 1.5rem;
        text-align: center;
        padding: 1rem;
        margin: 1rem 0 0;
      }
    }
  }
  &__icon {
    margin: 1.1rem 1.5rem;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &__subtitle {
    @extend .employee__info__title;
    font-weight: 400;
    color: #828282;
  }
}

.employee__main__component {
  margin: 1rem 0 1.3rem;

  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
}

.employee__title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.employee__information_row {
  display: flex;
}


.employee__heading {
  @extend .employee__info;
  
  align-items: center;
  margin-bottom: 1rem;
  gap: .8rem;
  
  img {
    width: 99px;
    height: 100px;
  }
}

</style>