<template>
  <div class="dashboard-calendar" >
    <p class="date" :class="{ 'date__deactivated': disabled}">
      {{ dateToShow }}
    </p>
  </div>
</template>

<script>


export default {
  props: {
    date: String,
    disabled: Boolean,
  },
  computed: {
    dateToShow() {
      return this.date?.replace(/(\d+)-(\d+)-(\d+)/, '$3.$2.$1') 
    },
  }
}
</script>

<style scoped>
</style>