var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"engineering_container layout__body"},[(_vm.loading)?[_c('CPLoading')]:_vm._e(),(!_vm.loading)?[_c('div',{staticClass:"engineering_title"},[_c('router-link',{staticClass:"engineering_title-link",attrs:{"to":{name: 'EngineeringDetailed', params: { projectId: _vm.projectId}},"replace":""}},[_c('p',{on:{"click":function($event){return _vm.setTitle('MDR')}}},[_vm._v("MDR")])]),_c('div',{staticClass:"engineering_calendar",on:{"click":_vm.toggeDatePicker}},[_c('Calendar',{attrs:{"date":_vm.picker}})],1),_c('router-link',{attrs:{"to":{name: 'EngineeringDetailed', params: { projectId: _vm.projectId}},"replace":""}},[_c('div',{staticClass:"folder_nav_arrow",on:{"click":function($event){return _vm.setTitle('MDR')}}},[_c('img',{attrs:{"src":require("@/assets/dashboard/icons/nav-arrow-right.svg"),"alt":""}})])])],1),(_vm.data && _vm.diff)?_c('div',{staticClass:"graph_container"},[_c('div',{staticClass:"graph"},[_c('div',{staticClass:"y-axis"},_vm._l((_vm.yAxis),function(y,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(y)+" ")])}),0),_c('div',{staticClass:"chart"},[_c('div',{staticClass:"cart-col designer-label"},[_c('div',{staticClass:"chart-col-1",style:({
                height: (_vm.data.designer_fact / _vm.data.planned) * 100 === 0 ? '3%' : (_vm.data.designer_fact / _vm.data.planned) * 100 + '%'
              })},[_c('div',{staticClass:"chart-col-bar"},[_c('div',{staticClass:"chart-col-progress"},[_vm._v(_vm._s(_vm.data.designer_fact))])]),_c('div',{staticClass:"chart-col-diff"},[_vm._v(_vm._s(_vm.diff.designer_fact))])])]),_c('div',{staticClass:"cart-col"},[_c('div',{staticClass:"chart-col-2",style:({
                height: (_vm.data.revision_fact / _vm.data.planned) * 100 === 0 ? '3%' : (_vm.data.revision_fact / _vm.data.planned) * 100 + '%',
              })},[_c('div',{staticClass:"chart-col-bar"},[_c('div',{staticClass:"chart-col-progress"},[_vm._v(_vm._s(_vm.data.revision_fact))])]),_c('div',{staticClass:"chart-col-diff"},[_vm._v(_vm._s(_vm.diff.revision_fact))])]),_c('div',{staticClass:"space revision-label",style:({
                height: (_vm.data.designer_fact / _vm.data.planned) * 100 === 0 ? '3%' : (_vm.data.designer_fact / _vm.data.planned) * 100 + '%'
              })})]),_c('div',{staticClass:"cart-col"},[_c('div',{staticClass:"chart-col-3",style:({
                height: (_vm.data.customer_fact / _vm.data.planned) * 100 + '%'
              })},[_c('div',{staticClass:"chart-col-bar"},[_c('div',{staticClass:"chart-col-progress chart-col-progress-last"},[_vm._v(_vm._s(_vm.data.customer_fact))])]),_c('div',{staticClass:"chart-col-diff label-border-none"},[_vm._v(_vm._s(_vm.diff.customer_fact))])]),_c('div',{staticClass:"space customer-label",style:({
                height: 
                  _vm.data.designer_fact === 0 && _vm.data.revision_fact === 0
                  ? '6%'
                  : _vm.data.designer_fact === 0 
                  ? ((_vm.data.designer_fact + _vm.data.revision_fact) / _vm.data.planned) * 100 + 3 + '%'  
                  : ((_vm.data.designer_fact + _vm.data.revision_fact) / _vm.data.planned) * 100 + '%'
              })})])])])]):_vm._e(),(_vm.showDatePicker)?_c('DatePicker',{attrs:{"min":_vm.minimalMonth,"date":_vm.picker,"availableDates":_vm.availableDates,"loadingDates":_vm.loadingDates},on:{"closeOverlay":function($event){return _vm.closeDatePicker($event)},"monthChanged":function($event){return _vm.getAvailableDates($event)},"dateChanged":function($event){return _vm.setCurrentDate($event)}}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }