<template>
  <v-overlay class="datePicker__overlay">
    <v-container v-click-outside="closeDatePicker" class="date-picker-container">
      <v-row justify="center">
        <v-date-picker
          class="date__picker-container"
          v-model="selectedDate"
          first-day-of-week="1"
          full-width
          no-title
          light
          color="rgba(239, 127, 26, 1)"
          locale="ru-RU"
          :allowed-dates="allowedDates"
          :picker-date.sync="pickedMonth"
          :weekday-format="setWeekDay"
          :min="min"
        >
          <div class="date_picker_element">
            <!-- <div v-if="!availableDates" class="text-center progress-container">
              <v-progress-circular
                :size="70"
                :width="7"
                color="#EF7F1A"
                indeterminate
              ></v-progress-circular>
            </div> -->
            <div>
              <v-progress-linear
                v-if="loading"
                height="36"
                rounded
                indeterminate
                color="#EF7F1A"
              ></v-progress-linear>
              <v-btn
                v-if="!loading"
                :disabled="availableDates === null || typeof availableDates == 'object' && availableDates.length === 0"
                @click="dateChanged"
                primary
                block
                color="#EF7F1A"
                class="datePickerButton no-uppercase"
              ><p class="datePickerText">{{ loading ? 'Загрузка доступных дат' 
                : availableDates === null ? 'Нет доступных дат' 
                : typeof availableDates == 'object' && availableDates.length === 0 ? 'Нет доступных дат' 
                : 'Выбрать'}}</p></v-btn>
            </div>
          </div>
        </v-date-picker>
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>
  export default {
    props: ['date', 'availableDates', 'min', 'loadingDates'],
    data () {
      return {
        // loading: true,
        selectedDate: this.date,
        pickedMonth: null,
        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        attrs: [
          {
            highlight: ''
          }
        ]
      }
    },
    watch: {
      pickedMonth(newDate) {
        if (newDate) {
          this.monthChanged()
        }
      },
    },
    methods: {
      closeDatePicker() {
        this.$emit('closeOverlay', false)
      },

      // emit: set new date
      dateChanged() {
        this.$emit('dateChanged', this.selectedDate);
        this.newDatePicked()
        this.closeDatePicker();
      },

      // emit: set new month
      monthChanged() {
        // console.log('month Changed from method!')
        this.$emit('monthChanged', this.pickedMonth)
      },

      // picked new date
      newDatePicked() {
        console.log('new date picked from method!')
      },

      // show only allowed dates
      allowedDates(val) {
        return this.availableDates?.indexOf(val) >= 0
      },
      
      // set week format
      setWeekDay(date){
      let i = new Date(date).getDay(date)
      return this.daysOfWeek[i]
      },
    },
    computed: {
      minimalMonth() {
        return this.min
      },
      loading() {
        if (this.loadingDates) {
          return true
        } return false
      }
    }
  }
</script>

<style scoped>
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  background: rgb(235, 235, 235) !important;
  color: grey !important;
}

.progress-container {
  width: 100vw;
}

@media (min-width: 60rem) {
  .progress-container {
    width: auto;
  }
}
.v-progress-circular {
  margin: 5rem auto;
}

.date__picker-container {
  display: grid;
  border-radius: 16px;
  font-weight: 600;
  font-size: 20px;
}

.datePicker__overlay {
  display: flex;
  align-items: flex-end;
}

.datePickerButton {
  color: #fff;
  padding: 1rem;
  border-radius: 1rem;
}

.datePickerButton > p {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.no-uppercase {
  text-transform: unset !important;
}

.datePickerText {
  margin: 1rem 0;
}

.date_picker_element {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

::v-deep .v-progress-linear--rounded {
  border-radius: 1rem;
}
</style>