<template>
  <div class="building_folder_container layout__body">

    <template>
      <div class="building-header">
        <div class="building-header-title">
          <h2>Объекты строительства</h2>
        </div>
        <div @click="toggleDatePicker">
          <Calendar :date="picker"/>
        </div>
      </div>
    </template>
    
    <!-- Cp loading -->
    <template v-if="loading">
      <CPLoading />
    </template>

    <!-- Error -->
    <DashMessage 
      v-if="error"
      message="Нет информации"
      hasXClose
      hasBtn
      @clear="handleBackNavigation"
      @btn-pressed="handleBackNavigation"
    />

    <!-- Loaded -->
    <template v-if="!loading">
      <div class="body">

        <!-- rows -->
        <div
          v-for="{id, title, unit, planned_count, in_fact, flag, progress} in list"
          :key="id"
          class="element-building_folder_container"
        >
          <div class="empty-space"></div>

          <!-- row -->
          <div class="element">
            <div class="title">
              <div>
                <img :src="require(`@/assets/dashboard/flag-icons/flag-${flag}.svg`)" alt="" class="flag">
              </div>
              <div>
                <p>{{title}}</p>
              </div>
            </div>
            <div class="type">{{ unit === 'человекочасы' ? 'ч/час' : unit }}</div>
            <div class="bar">
              <div
                :class="{filled: in_fact}"
                :style="{'width': progress === 100 ? '100%' 
                : progress === 0 ? '0%'
                : progress >= 70 ? 70 + '%'
                : ( (planned_count - in_fact) / planned_count ) * 100 + '%' }"
              >
                {{ in_fact === 0 ? '' : in_fact }}
              </div>
              <div
                class="unfilled"
                v-if="planned_count - in_fact !== 0"
                :style="{'width': progress === 100 ? '0%' 
                : progress === 0 ? '100%'
                : progress >= 70 ? 30 + '%'
                : ( (planned_count - in_fact) / planned_count ) * 100 + '%' }"
              ><div> {{ planned_count - in_fact }}</div></div>
            </div>
            <div class="percent">{{ progress }}%</div>
          </div>
        </div>

        <!-- legends -->
        <div class="legends">
          <div 
          v-for="({flag, title}, i) in legends"
          :key="i"
          class="legend"
        >
          <div class="legend__element">
            <img :src="require(`@/assets/dashboard/flag-icons/flag-${flag}.svg`)" alt="" class="flag">
            <span class="legend__title">{{ title }}</span>
          </div>
        </div>
        </div>
      </div>

      <!-- DatePicker component -->
      <DatePicker
        v-if="showDatePicker"
        @closeOverlay="closeDatePicker($event)"
        @monthChanged="getAvailableDates($event)"
        @dateChanged="changeDatePicker($event)"
        :date="picker"
        :min="min"
        :loadingDates="loadingDates"
        :availableDates="availableDates"
      ></DatePicker>
    </template>
  </div>
</template>

<script>
import dashboardApi from '@/helpers/dashboardApi';
import { backNavigateQueue } from "@/helpers/app.js";
import CPLoading from '../../components/Dashboard/CPLoading.vue';
import Calendar from '../../components/Dashboard/Calendar.vue';
import DatePicker from '@/components/Dashboard/DatePicker.vue';
import axios from 'axios';
import DashMessage from '@/components/Dashboard/DashMessage.vue'
import { mapActions } from "vuex";
import bridge from "@/helpers/bridge";

export default {
  components: {
    CPLoading,
    Calendar,
    DatePicker,
    DashMessage,
  },
  data() {
    return {
      loading: true,
      loadingDates: false,
      legends: [
        {
          flag: 'blue',
          title: 'Завершено'
        },
        {
          flag: 'green',
          title: 'В плане'
        },
        {
          flag: 'yellow',
          title: 'Отставание от 5% до 10%'
        },
        {
          flag: 'burgundy',
          title: 'Отставание от 10% до 20%'
        },
        {
          flag: 'red',
          title: 'Отставание более 20%'
        },
      ],
      projectId: this.$route.params.projectId,
      list: null,

      // toggles
      showDatePicker: false,

      // Dates
      availableDates: null,

      // axios cancel requests
      getDatesCancelToken: undefined,
    }
  },
  computed: {
    picker() {
      return this.$store.getters['cp/bePicker'];
    },
    min() {
      return this.$store.getters['cp/beMinDate'];
    },
    error() {
      return this.$store.getters['cp/beError'];
    },
  },
  methods: {
    ...mapActions({
      resetError: 'cp/resetBeError'
    }),
    getIcon(color) {
      return `@/assets/dashboard/flag-icons/flag-${color}.svg`
    },
    handleBackNavigation() {
      return this.$router.replace({name: 'ProjectsPage'})
    },
    async getData(date) {
      try {
        const res = await dashboardApi(`/project/${this.projectId}/building/be/${date}/`);
        this.list = res.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    closeDatePicker(e) {
      this.showDatePicker = e;
    },
    async getAvailableDates(month) {
      try {
        this.loadingDates = true;

        if (typeof this.getDatesCancelToken != typeof undefined) {
          this.getDatesCancelToken.cancel('Operation canceled due to new request.');
        }

        this.getDatesCancelToken = axios.CancelToken.source();

        const res = await dashboardApi(`/project/1/building/be/date/${month}/`, {
          cancelToken: this.getDatesCancelToken.token
        });
        this.availableDates = res.data;

        this.loadingDates = false;
      } catch (error) {
        console.log(error);
      }
    },
    changeDatePicker(event) {
      this.$store.dispatch('cp/changeBeDate', event);
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
  },
  async created() {
    this.$store.dispatch('cp/setBeDatePicker', this.projectId);
    this.picker && this.getData(this.picker)
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    bridge.disableScreenCapture();
  },
  watch: {
    picker() {
      this.getData(this.picker);
    }
  },
  destroyed() {
    this.resetError();
  },
  beforeDestroy() {
    bridge.enableScreenCapture();
  },
}
</script>

<style lang="scss" scoped>
.building_folder_container {
  background: #fff;
}

h2 {
  color: #151C22;
  font-weight: 500;
  font-size: 1.24rem;
  line-height: 1.5rem;
}

.header {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem;
  background: #fff;
  border-radius: 18px 18px 0 0;
}
.body {
  background: #fff;
  margin-top: .5rem;
}
.empty-space {
  height: 25px;
}
.element {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr 1fr;
  color: rgba(16, 24, 40, 1);
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 400;
  padding: .25rem 1rem 0;
  gap: .5rem;
  align-items: center
}

.title {
  display: grid;
  grid-template-columns: 20% 70%;
  min-width: 100px;
  background: #E0E0E0;
  border-radius: 12px;
  padding: 2px 4px;
  gap: .3rem;
}

.title p {
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 400;
}
.title > div {
  word-wrap: break-word;
}

.title:first-child img {
  padding-top: 50%;
}


.type,
.percent {
  display: flex;
  align-items: center;
  background: #E0E0E0;
  border-radius: 12px;
  padding: 2px 4px;
}

.type {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3rem;
}
/* bar */
.bar {
  display: flex;
  align-items: center;
  border: 1px solid #DDE1E5;
  flex-grow: 1;
  position: relative;
  border-radius: 12px;
}

.filled,
.unfilled {
  display: flex;
  border-radius: 12px;
  padding: 2px 12px;
  justify-content: center;
}

.filled {
  background: #E0E0E0;
  min-width: 2rem;
  position: relative;
}

.added {
  text-align: center;
  position: absolute;
  right: 5px;
  top: -1.8rem;
  background: url('../../assets/dashboard/flag-icons/bubble-light.svg') no-repeat center center/cover;
  width: 30px;
  height: 25px;
}

.filled::after {
  content: var(--added);
  background: url('../../assets/dashboard/flag-icons/bubble-light.svg') no-repeat center center/cover;
  position: absolute;
  right: 0;
  width: 30px;
  height: 25px;
  text-align: center;
  right: 5px;
  top: -1.8rem;
  color: rgba(16, 24, 40, 1);
  font-weight: 600;
  font-size: .875rem;
}

.unfilled {
  background: #fff;
}

/* percent */
.percent {
  justify-content: center;
}

.legends {
  padding: 1rem;
  margin-top: 1rem;
}

.legend__element {
  display: flex;
  gap: .5rem
}

.legend__title {
  font-weight: 400;
  font-size: .8125rem;
  line-height: 1.25rem;
}

.type,
.bar {
  min-height: 20px;
}

.building-header {
  display: flex;
  justify-content: space-between;
  margin: .8rem 1rem .25rem;
  
  &-title h2 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
</style>