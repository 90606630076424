<template>
  <div class="schedule__container layout__body">
    <!-- Cp loading -->
    <template v-if="loading">
      <CPLoading />
    </template>

    <!-- Loaded -->
    <template v-if="!loading">
      <div
        v-for="({title, in_fact, planned, progress}, i) in list"
        :key="i"
        class="bar__element"
      >
        <div class="body">
          <div class="body__header">
            <span>
              {{title}}
            </span>
            <div @click="title === 'Исполнительная документация' ? toggleDocsDatePicker() : toggleTotalDatePicker()">
              <Calendar class="calendar" :date="title === 'Исполнительная документация' ? docsPicker : totalPicker"/>
            </div>
          </div>
          <div class="bar">
            <div class="bar__progress">
              <div 
                class="filled"
                :style="{'background': '#828282', 'width': progress +'%'}"
              >
                <span class="percent done">{{ in_fact }}</span>
              </div>
              <div
                class="unfilled"
                :style="{'width': 100 - (100 * in_fact) / planned +'%'}"
              >
                <span class="percent left">{{ planned - in_fact }}</span>
              </div>
            </div>
            <div class="percent_total">
              <span class="percent indicator">
                {{ progress }}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <EmptyDash v-if="error" message="Нет информации"/>
      
      <!-- Date picker docs -->
      <DatePicker
        v-if="showDocsDatePicker"
        @closeOverlay="closeDocsDatePicker($event)"
        @monthChanged="getAvailableDates($event)"
        @dateChanged="changeDocsDatePicker($event)"
        :date="docsPicker"
        :min="min"
        :loadingDates="loadingDates"
        :availableDates="availableDocsDates"
      />

      <!-- Date picker total -->
      <DatePicker
        v-if="showTotalDatePicker"
        @closeOverlay="closeTotalDatePicker($event)"
        @monthChanged="getAvailableDates($event)"
        @dateChanged="changeTotalDatePicker($event)"
        :date="totalPicker"
        :min="min"
        :loadingDates="loadingDates"
        :availableDates="availableDocsDates"
      />

    </template>
  </div>
</template>

<script>
import dashboardApi from '@/helpers/dashboardApi';
import Calendar from '../../components/Dashboard/Calendar.vue';
import { backNavigateQueue } from "@/helpers/app.js";
import EmptyDash from '@/components/Dashboard/EmptyDash.vue'
import CPLoading from '../../components/Dashboard/CPLoading.vue';
import DatePicker from '@/components/Dashboard/DatePicker.vue';
import axios from 'axios';
import { mapActions } from "vuex";
import bridge from "@/helpers/bridge";

export default {
  components: {
    Calendar,
    EmptyDash,
    CPLoading,
    DatePicker,
  },
  data() {
    return {
      /*
        state
      */

      loading: true,
      // error: false,

      /*
        data
      */

      projectId: this.$route.params.projectId,
      list: null,
      total: null,

      /*
        toggles
      */
      
      showDocsDatePicker: false,
      showTotalDatePicker: false,
      loadingDates: false,

      /*
        Dates
      */
     
      availableDocsDates: null,

      /*
        axios cancel tokens
      */
      getDatesCancelToken: undefined,
    }
  },
  computed: {
    docsPicker() {
      return this.$store.getters['cp/docsPicker'];
    },
    totalPicker() {
      return this.$store.getters['cp/totalPicker']
    },
    min() {
      return this.$store.getters['cp/docsMinDate'];
    },
    error() {
      return this.$store.getters['cp/docsError']
    }
  },
  async created() {
    this.$store.dispatch('cp/setDocsPicker', this.projectId);
    this.docsPicker && this.getDocsData(this.docsPicker);
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    bridge.disableScreenCapture();
  },
  methods: {
    ...mapActions({
      resetError: 'cp/resetDocsError'
    }),
    // routing
    handleBackNavigation() {
      this.$router.replace({
        name: 'ProjectsPage'
      })
    },

    // toggles
    closeDocsDatePicker(e) {
      this.showDocsDatePicker = e;
    },
    closeTotalDatePicker(e) {
      this.showTotalDatePicker = e;
    },
    toggleDocsDatePicker() {
      this.showDocsDatePicker = !this.showDocsDatePicker;
    },
    toggleTotalDatePicker() {
      this.showTotalDatePicker = !this.showTotalDatePicker
    },

    // get async data
    async getAvailableDates(month) {
      try {
        // loading dates state
        this.loadingDates = true;

        // cancel request if token exists
        if (typeof this.getDatesCancelToken != typeof undefined) {
          this.getDatesCancelToken.cancel('Operation canceled due to new request.');
        }
        
        // set cancel token
        this.getDatesCancelToken = axios.CancelToken.source();

        // api call
        const res = await dashboardApi(`/project/${this.projectId}/docs/date/${month}/`, {
          cancelToken: this.getDatesCancelToken.token
        });
        this.availableDocsDates = res.data

        // loading dates state
        this.loadingDates = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getDocsData(date) {
      try {     
        const res = await dashboardApi(`/project/${this.projectId}/docs/${date}/`)
        const res2 = await dashboardApi(`/project/${this.projectId}/docs/total/${date}/`)
        this.list = [... res.data, res2.data]
      } catch (error) {
        console.log(error)
        this.error = true;
      } finally {
        this.loading = false;
      }
    },

    // dates
    changeDocsDatePicker(event) {
      this.$store.dispatch('cp/changeDocsPicker', event)
    },
    changeTotalDatePicker(event) {
      this.$store.dispatch('cp/changeTotalPicker', event)
    },
  },
  watch: {
    docsPicker() {
      this.getDocsData(this.docsPicker);
    },
    totalPicker() {
      this.getDocsData(this.docsPicker);
    },
    error() {
      this.loading = false
    }
  },
  destroyed() {
    this.resetError();
  },
  beforeDestroy() {
    bridge.enableScreenCapture();
  },
}
</script>

<style scoped>
.schedule__container {
  display: flex;
  flex-direction: column;
  gap: 13px;
  background-color: rgba(249, 249, 249, 1);
  padding-top: .5rem;
  height: 100vh;
}
h2{
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 24px;
  margin: .5rem 1rem;
}
.body {
  background-color: #fff;
  padding: .975rem 1rem;
  margin: 1px 0;
}
.body__header {
  display: flex;
  justify-content: space-between;
  position: relative;
  gap: 1rem;
}

span {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #151C22;
}

.bar {
  display: grid;
  grid-template-columns: 1fr min-content;
  margin: 1.625rem 0 .875rem;
}

.filled {
  border-radius: 12px 0 0 12px;
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 15%;
}
.filled span {
  color: #fff;
}

.unfilled {
  display: flex;
  justify-content: center;
}
.bar__progress {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid #DDE1E5;
  border-radius: 12px;
}

.percent {
  font-size: .875rem;
  line-height: 1.25rem;
  letter-spacing: 0.02em;
}

.done {
  font-weight: 600;
}

.left {
  font-weight: 400;
}

.indicator {
  font-weight: 600;
  color: #fff;
  padding: 0 12px;
}

.percent_total {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: .5rem;
  gap: 4px;
  background: #828282;
  border-radius: 12px;
}

/* .filled::after {
  content: '+6';
  background: url('../../assets/dashboard/flag-icons/bubble.svg') no-repeat center center/cover;
  position: absolute;
  right: -15px;
  top: -1.7rem;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 30px;
  height: 25px;
  text-align: center;
} */



</style>