<template>
  <div class="layout__body dashboard__projects_list">

    <!-- 
      loading circle 
    -->

    <v-overlay v-if="loading" opacity="1" :color="'#FFF'">
      <CPLoading />
    </v-overlay>

    <!-- 
      if error 
    -->
    
    <DashMessage
      v-if="authErr || empty"
      :message="message"
      hasXClose
      hasBtn
      @clear="handleBackNavigation"
      @btn-pressed="handleBackNavigation"
    ></DashMessage>

    <!-- 
      Main content 
    -->

    <template>
      <div class="dash_projects_wrapper">
        <!-- 
          Search bar 
        -->

        <div class="search_bar">
          <SearchInput :textCentered="true"/>
        </div>
        
        <!-- Skeletons
        <template v-if="loading">
          <v-skeleton-loader
          type="list-item-three-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-two-line"
          ></v-skeleton-loader>
        </template>  -->

        <!-- 
          Content 
        -->
        <div v-if="!loading">

          <!-- 
            1. programs begins 
          -->

          <div
            v-for="{id, title} in list.programs"
            :key="id + Math.random()"
          >
            <router-link :to="{name: 'Programs', params: {programId: id, divisionId: divisionId}}" replace>
              <div class="project__list folder__list">
                <div class="folder-icon">
                  <img src="@/assets/dashboard/icons/Folder.svg" alt="">
                </div>
                <div class="folder-label folder__label">
                  <p class="project__label">
                    {{ title }}
                  </p>
                </div>
                <div class="nav-arrow-right">
                  <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
                </div>
              </div>
            </router-link>
          </div>

          <!-- 
            2. Project list begins 
          -->

          <div
            v-for="({id, title}) in list.projects"
            :key="id + Math.random()"
            style="{}"
          >
            <router-link
              :to="{name: 'ProjectsPage', params: {projectId: id}}" replace
            >
              <div class="project__list">
                <div>
                  <p class="project__label">
                    {{ title }}
                  </p>
                </div>
                <div class="project__nav-arrow">
                  <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>  
    </template>
  </div>
</template>

<script>
import SearchInput from '../../components/partials/SearchInput.vue';
import dashboardApi from '@/helpers/dashboardApi';
import { backNavigateQueue } from "@/helpers/app.js";
import DashMessage from '@/components/Dashboard/DashMessage.vue'
import CPLoading from '@/components/Dashboard/CPLoading.vue'
import { mapActions } from "vuex";
import bridge from "@/helpers/bridge";

export default {
  components: {
    SearchInput,
    DashMessage,
    CPLoading
  },
  data() {
    return {
      divisionId: parseInt(this.$route.params.divisionId),
      list: null,
      // authErr: false,
      noData: false,
      loading: true,
    }
  },
  async created() {
    try {
      const res = await dashboardApi(`/division/${this.divisionId}/`);
      this.list = res.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    handleBackNavigation() {
      this.$router.replace({
        name: 'Divisions'
      })
    },
    ...mapActions({
      resetBudget: 'cp/resetBudgetDates',
      resetMDR: 'cp/resetMdrDates',
      resetOto: 'cp/resetOto',
      resetBE: 'cp/resetBE',
      resetDocs: 'cp/resetDocs',
    }),
    setTitle(t) {
      this.$store.dispatch('layout/setHeaderTitle', t);
    }
  },
  mounted() {
    bridge.enableScreenCapture();
    backNavigateQueue.set(this.handleBackNavigation);
    this.resetBudget();
    this.resetMDR();
    this.resetOto();
    this.resetBE();
    this.resetDocs();
  },
  computed: {
    title() {
      return this.list?.title === 'Мульти программы' ? 'Мульти программы' 
        : this.list?.length === 0 ? 'Пустой дивизион'
        : `${this.list?.title} дивизион`
    },
    authErr() {
      return this.list?.length === 0 ? true : false
    },
    empty() {
      return this.list?.programs?.length === 0 && this.list?.projects?.length === 0 ? true : false
    },
    message() {
      return this.authErr ? 'Нет доступных проектов' 
        : this.empty ? 'В дивизионе нет проектов'
        : null
    },
  },
  watch: {
    title() {
      this.setTitle(this.title)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>