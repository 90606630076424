var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"staff__container layout__body"},[(!_vm.stagesLoading)?_c('div',{staticClass:"stages"},[_vm._l((_vm.stages),function(ref){
var id = ref.id;
var title = ref.title;
return _c('div',{key:id,staticClass:"stage",class:{active: _vm.activeTab === id},on:{"click":function($event){_vm.getStaffByStageId(id); _vm.activeTab = id}}},[_vm._v(" "+_vm._s(title)+" ")])}),_c('div',{staticClass:"calendar__position",on:{"click":_vm.toggeDatePicker}},[_c('Calendar',{attrs:{"date":_vm.picker}})],1),(_vm.showDatePicker)?_c('DatePicker',{attrs:{"date":_vm.picker,"min":_vm.minimalMonth,"availableDates":_vm.availableDates,"loadingDates":_vm.loadingDates},on:{"closeOverlay":function($event){return _vm.closeDatePicker($event)},"dateChanged":function($event){return _vm.setCurrentDate($event)},"monthChanged":function($event){return _vm.getAvailableDates($event)}}}):_vm._e()],2):_vm._e(),(_vm.stagesLoading)?_c('div',{staticClass:"stages-loading"},[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1):_vm._e(),(_vm.showInfo)?_c('EmployeeInfo',{attrs:{"pickedPerson":_vm.pickedPerson},on:{"closeEmployeeOverlay":function($event){return _vm.closeInfoOverlay($event)}}}):_vm._e(),(!_vm.totalCountLoading)?_c('div',_vm._l((_vm.count.filter(function (item) { return item.stage === _vm.activeTab; })),function(ref){
var id = ref.id;
var in_fact = ref.in_fact;
var planned = ref.planned;
return _c('div',{key:id,staticClass:"total-table"},[_c('div',[(!_vm.totalCountLoading)?_c('p',[_vm._v(" "+_vm._s(planned)+" ")]):_vm._e()]),_c('div',[_c('p',[_vm._v(_vm._s(in_fact))])])])}),0):_vm._e(),(_vm.totalCountLoading)?_c('div',{staticClass:"total-loader"},[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1):_vm._e(),(!_vm.staffLoading)?_c('div',{staticClass:"table-body"},_vm._l((_vm.staff),function(ref){
var id = ref.id;
var employee_name = ref.employee_name;
var position = ref.position;
var email = ref.email;
return _c('div',{key:id,staticClass:"table-row"},[_c('p',{class:{'staff-active': employee_name}},[_vm._v(_vm._s(position))]),_c('div',[_vm._l((employee_name),function(employee,i){return _c('div',{key:i},[_c('p',{class:{'tdb': !employee_name},on:{"click":function($event){return _vm.callOverlay([email[i], position])}}},[_vm._v(" "+_vm._s(!employee_name.length ? "TBD" : employee)+" ")])])}),(!employee_name.length)?_c('div',[_c('p',{staticClass:"tdb",class:{'tdb': !employee_name}},[_vm._v(" "+_vm._s("TBD")+" ")])]):_vm._e()],2)])}),0):_vm._e(),(_vm.staffLoading)?_c('div',{staticClass:"staff-loader"},[_c('v-skeleton-loader',{attrs:{"type":"table-cell@6"}}),_c('v-skeleton-loader',{attrs:{"type":"table-cell@6"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }