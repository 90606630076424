<template>
  <div class="elements">
    
    <!-- loop -->
    <div
      class="element"
      v-for="{ id, title, designer_fact, revision_fact, customer_fact, flag, planned, customer_progress_planned, customer_progress_fact } in data"
      :key="id"
    >
      <!-- title -->
      <div class="element_title"
        @click="toggeActive"
      >
        <img :src="require(`@/assets/dashboard/flag-icons/flag-${flag}.svg`)" alt="">
        <p>{{ title }}</p>
      </div>

      <div class="table">
        <div class="element_planned"><p>{{planned}} ед</p></div>
        <div class="element_bar">
          <div class="lines">
            <div class="line line1__bar"
              :style="{
                'width': designer_fact === 0? '1%'
                : ((designer_fact / planned) * 100) === 100 ? '95%'
                : (designer_fact / planned) * 100 + '%'
              }"
            ></div>
            <div v-if="isActive" class="line1__fact">{{ designer_fact }}</div>
          </div>
          <div class="lines">
            <div class="line line2__bar"
              :style="{
                'width': revision_fact === 0 ? '1%'
                : (revision_fact / planned ) * 100 === 100 ? '94%' 
                : (revision_fact / planned ) * 100 + '%',
                'margin-left': 
                designer_fact === 0? '1%' 
                : ((designer_fact / planned) * 100) === 100 ? '95%' 
                : (designer_fact / planned) * 100 + '%'
              }"
            ></div>
            <div v-if="isActive" class="line2__fact"> {{ revision_fact }}</div>
          </div>
          <div class="lines">
            <div class="line line3__bar"
              :style="{
                'width': customer_fact === 0 ? '5%'
                : revision_fact === 0 && designer_fact === 0 ? (customer_fact / planned ) * 100 - 2 + '%' 
                : (customer_fact / planned ) * 100 + '%',
                'margin-left': revision_fact === 0 && designer_fact === 0 ? '2%' 
                : revision_fact === planned ? 95 + '%'
                : designer_fact === 0 && revision_fact !== 0 ? (revision_fact / planned ) * 100 + 1 + '%'
                // : designer_fact !== 0 && revision_fact === 0 ? (designer_fact / planned ) * 100 + 1 + '%'
                : ((designer_fact + revision_fact) / planned) * 100 === 100 ? '95%'
                : (((revision_fact / planned ) * 100) + ((designer_fact / planned) * 100)) + '%'
              }"
            >
              <div v-if="isActive" class="line3__fact">{{ customer_fact }}</div>
            </div>
          </div>
        </div>
        
        <div class="percents">
          <div class="percent"><p>{{customer_progress_fact}}%</p></div>
          <div v-if="isActive" class="percent toggled"><p>{{customer_progress_planned}}%</p></div>
        </div>
      </div>
    </div>
  <!-- end of loop -->
  </div>
</template>

<script>

export default {
  props: ['item'],
  data() {
    return {
      data: [this.item],
      isActive: false,
    }
  },
  methods: {
    toggeActive() {
      this.isActive = !this.isActive
    }
  }
}

</script>

<style scoped>

.element_title {
  display: flex;
  border: 1px solid rgba(130, 130, 130, 1);
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-top: 1.5rem;
  gap: .25rem;
  background: #F2F2F2;
}

.element_title > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 2px 0 2px;
}

.table {
  display: flex;
  margin-top: 1rem;
  gap: .5rem;
}

.element {
  margin-bottom: 2rem;
}

.percents {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.percent {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #56CCF2;
  border-radius: 12px;
  color: rgba(255, 255, 255, 1);
  min-width: 3rem;
  margin-left: auto;
}

.percent p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.element_planned p {
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  background: #E0E0E0;
  border-radius: 12px;
  min-width: 2.5rem;
  text-align: center;
  padding: 2px 4px;
}

.element_planned {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

  /* bar */
.element_bar {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.lines {
  display: flex;
  border-radius: 2px;
  flex: 1;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  gap: .2rem;
}

.line {
  border-radius: 2px;
}

.line1__bar {
  background: #9B51E0;
}

.line2__bar {
  background: #90BC34;
}

.line3__bar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #56CCF2;
}

  /* flags and legends */
.toggled {
  background: #DDE1E5;
  color: #101828
}

.line1__fact,
.line2__fact,
.line3__fact {
  display: flex;
  align-items: center;
}
.line3__fact {
  justify-content: center;
}
</style>