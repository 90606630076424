<template>
  <div 
    class="input-div"
    :class="{
      'input-div--focused': focused
    }"
    @focus.capture="handleFocus"
    @blur.capture="focused = false"
  >
    <input 
      :value="value"
      @input="$emit('input', $event.target.value)"
      type="text"
      class="input-div__search-input"
      :class="{
        'search-input--text-centered': textCentered,
      }"
      :placeholder="$translate('search-input_placeholder')"
    >
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'20'" v-bind:height="'20'" v-bind:class="'input-div__search-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.827 11.467a6.11 6.11 0 1112.22 0 6.11 6.11 0 01-12.22 0zm6.11-8.11a8.11 8.11 0 105.76 13.82 1 1 0 00.161.211l2.608 2.608a1 1 0 001.414-1.414l-2.608-2.608a.995.995 0 00-.428-.254 8.11 8.11 0 00-6.906-12.363z"/></svg> 
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="value" @click.prevent="$emit('input', '')" v-bind:svg-inline="''" v-bind:class="'input-div__close-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.293 20.294a1 1 0 001.414 1.414l6.294-6.294 6.295 6.296a1 1 0 001.415-1.414L15.415 14l6.293-6.293a1 1 0 10-1.414-1.414L14 12.586 7.71 6.295a1 1 0 00-1.414 1.414L12.586 14l-6.293 6.294z" fill="#151C22"/></svg>  

  </div>
</template>
<script>
export default {
  props: {
    textCentered: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    }
  },
  data(){
    return {
      focused: false,
    }
  },
  methods: {
    handleFocus(){
      this.focused = true;
      this.$emit('onClick');
    }
  }
}
</script>
<style lang="scss">
.input-div{
  position: relative;
  width: 100%;
  color: #8E8E93;
  &.input-div--focused{
    color: #151C22;
  }
  .input-div__search-input{
    width: 100%;
    padding: 6px 32px;
    background: #9295981f;
    border: none;
    border-radius: 6px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
  }
  .search-input--text-centered{
    text-align: center;
  }
  .input-div__search-icon{
    fill: currentColor;
    // stroke: currentColor;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: auto;
    margin-left: 0.5rem;
    pointer-events: none;
  }
  .input-div__close-icon{
    // fill: black;
    // stroke: currentColor;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: auto;
    margin-right: 1rem;
    // cursor: pointer;  
  }
}
</style>