var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout__body dash__watermark",style:({backgroundImage: _vm.watermark})},[(_vm.loading)?[_c('CPLoading')]:_vm._e(),(!_vm.loading && _vm.watermark)?[_c('div',{staticClass:"budget_detailed_container"},[_c('div',{staticClass:"body top-margin"},[_c('div',{staticClass:"body__header"},[_c('span',[_vm._v(" "+_vm._s(_vm.subtype === 'annual' ? 'Годовой бюджет на' : 'Общий бюджет на')+" ")]),_c('BudgetDatePicker',{attrs:{"type":_vm.type}})],1),_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar__progress"},[_c('div',{staticClass:"bar__filled",style:({
                'background': '#828282',
                'width': _vm.total.progress >= 100 ? '100%' : _vm.total.progress <= 30 ? 30 + '%' : _vm.total.progress + '%',
                'border-radius': _vm.total.progress >= 100 ? '12px' : '' 
              })},[_c('span',{staticClass:"percent done"},[_vm._v(_vm._s(_vm.total.in_fact))]),(_vm.total.planned - _vm.total.in_fact < 0)?_c('div',{staticClass:"budget_buble"},[_c('div',{staticClass:"speech-bubble"},[_vm._v(" "+_vm._s(_vm.total.planned - _vm.total.in_fact)+" ")])]):_vm._e()]),_c('div',{staticClass:"bar__unfilled",style:({
                'width': _vm.total.progress > 100 ? '0%' : (100 - _vm.total.progress) + '%'
              })},[_c('span',{staticClass:"percent left"},[_vm._v(_vm._s(_vm.total.progress >= 100 ? '' : _vm.total.diff))])])]),_c('div',{staticClass:"percent_total"},[_c('span',{staticClass:"percent indicator"},[_vm._v(" "+_vm._s(_vm.total.progress)+"% ")])])])]),_c('v-container',{staticClass:"v-container"},_vm._l((_vm.budgetIndicatorsDetailed),function(ref){
              var id = ref.id;
              var title = ref.title;
              var in_fact = ref.in_fact;
              var progress = ref.progress;
              var planned = ref.planned;
              var flag = ref.flag;
return _c('v-row',{key:id,staticClass:"v-row"},[_c('v-col',{staticClass:"col1",attrs:{"cols":"auto"}},[(flag && _vm.subtype === 'annual')?_c('div',{staticClass:"flag"},[_c('img',{attrs:{"src":require(("@/assets/dashboard/flag-icons/flag-" + flag + ".svg")),"alt":""}})]):_vm._e(),_vm._v(" "+_vm._s(title)+" ")]),_c('v-col',{staticClass:"col2"},[_c('div',[_c('div',{staticClass:"in_fact",style:({
                  'width': progress >= 100 ? '100%' 
                    : progress <= 30 ? '30%'
                    : progress > 80 ? '70%'
                    : progress + '%',
                  'background': '#E0E0E0',
                  'border-radius': progress >= 100 ? '12px' : ''
                })},[_c('div',[_vm._v(_vm._s(in_fact))]),(planned - in_fact < 0)?_c('div',{staticClass:"budget_buble"},[_c('div',{staticClass:"speech-bubble"},[_vm._v(" "+_vm._s(Math.round((planned - in_fact) * 10 ) / 10)+" ")])]):_vm._e()]),_c('div',{staticClass:"remaining",style:({
                  'width': progress >= 100 ? '0%'
                    : progress <= 30 ? '70%'
                    : progress > 80 ? '30%'
                    : (100 - progress) + '%',
                })},[_c('div',{style:({
                    'color': (planned - in_fact) < 0 ? 'red' : ''
                  })},[_vm._v(" "+_vm._s(progress >= 100 ? '' : Math.round((planned - in_fact) * 10) / 10)+" ")])])])]),_c('v-col',{staticClass:"col3",attrs:{"cols":"auto"}},[_c('div',{staticClass:"list__percent"},[_vm._v(" "+_vm._s(Math.trunc(progress))+"% ")])])],1)}),1),_c('BudgetLegends',{attrs:{"type":"detailed","disabled":_vm.disableFlags}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }