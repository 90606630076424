<template>
  <div>
    <template v-if="show === 'main' && !disabled">
      <div class="legends">
        <div>
          <img src="@/assets/dashboard/flag-icons/flag-green.svg" alt="">
          <span class="legend__label">В плане</span>
        </div>
        <div>
          <img src="@/assets/dashboard/flag-icons/flag-yellow.svg" alt="">
          <span class="legend__label">Отставание от 10% до 20%</span>
        </div>
        <div>
          <img src="@/assets/dashboard/flag-icons/flag-red.svg" alt="">
          <span class="legend__label">Отставание более 20%</span>
        </div>
      </div>
    </template>
    <template v-if="show === 'detailed' && !disabled">
      <div class="legends">
        <div 
        v-for="({flag, title}, i) in legends"
        :key="i"
        class="legend"
        >
          <div class="legend__element">
            <img :src="require(`@/assets/dashboard/flag-icons/flag-${flag}.svg`)" alt="" class="flag">
            <span class="legend__title">{{ title }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  computed: {
    show() {
      return this.type === 'main' ? 'main' 
        : this.type === 'detailed' ? 'detailed'
        : null
    },
  },
  data() {
    return {
      legends: [
        {
          flag: 'blue',
          title: 'Завершено'
        },
        {
          flag: 'green',
          title: 'В плане'
        },
        {
          flag: 'yellow',
          title: 'Отставание от 5% до 10%'
        },
        {
          flag: 'burgundy',
          title: 'Отставание от 10% до 20%'
        },
        {
          flag: 'red',
          title: 'Отставание более 20%'
        },
      ],
    }
  }
}
</script>