<template>
  <div class="layout__body dash__watermark" :style="{backgroundImage: watermark}">
    <!-- Loading -->
    <template v-if="loading">
      <CPLoading />
    </template>

    <!-- Main content loaded -->
    <template v-if="!loading && watermark">
      <div class="budget_detailed_container">
        <div class="body top-margin">
          <div class="body__header">
            <span>
              {{ subtype === 'annual' ? 'Годовой бюджет на' : 'Общий бюджет на' }}
            </span>
            <BudgetDatePicker :type="type"/>
          </div>

          <!-- total budget header -->
          <div class="bar">
            <div class="bar__progress">
              <div 
                class="bar__filled"
                :style="{
                  'background': '#828282',
                  'width': total.progress >= 100 ? '100%' : total.progress <= 30 ? 30 + '%' : total.progress + '%',
                  'border-radius': total.progress >= 100 ? '12px' : '' 
                }"
              >
                <span class="percent done">{{ total.in_fact }}</span>
                <div class="budget_buble"
                  v-if="total.planned - total.in_fact < 0"
                >
                  <div class="speech-bubble">
                    {{ total.planned - total.in_fact }}
                  </div>
                  <!-- <img src="@/assets/dashboard/flag-icons/bubble.svg" alt=""> -->
                </div>
              </div>
              <div
                class="bar__unfilled"
                :style="{
                  'width': total.progress > 100 ? '0%' : (100 - total.progress) + '%'
                }"
              >
                <span class="percent left">{{ total.progress >= 100 ? '' : total.diff }}</span>
              </div>
            </div>
            <div class="percent_total">
              <span class="percent indicator">
                {{ total.progress }}%
              </span>
            </div>
          </div>
        </div>

        <v-container class="v-container">
          <v-row
            v-for="{ id, title, in_fact, progress, planned, flag } in budgetIndicatorsDetailed"
            :key="id"
            class="v-row"
          >
            <v-col cols="auto" class="col1">
              <div class="flag" v-if="flag && subtype === 'annual'"><img :src="require(`@/assets/dashboard/flag-icons/flag-${flag}.svg`)" alt=""></div>
              {{title}}
            </v-col>
            <v-col class="col2">
              <div>
                <div class="in_fact"
                  :style="{
                    'width': progress >= 100 ? '100%' 
                      : progress <= 30 ? '30%'
                      : progress > 80 ? '70%'
                      : progress + '%',
                    'background': '#E0E0E0',
                    'border-radius': progress >= 100 ? '12px' : ''
                  }"
                >
                <div>{{ in_fact }}</div>
                <div class="budget_buble"
                    v-if="planned - in_fact < 0"
                >
                  <div class="speech-bubble">
                    {{ Math.round((planned - in_fact) * 10 ) / 10 }}
                  </div>
                    <!-- <img src="@/assets/dashboard/flag-icons/bubble.svg" alt=""> -->
                </div>
              </div>
                <div class="remaining"
                  :style="{
                    'width': progress >= 100 ? '0%'
                      : progress <= 30 ? '70%'
                      : progress > 80 ? '30%'
                      : (100 - progress) + '%',
                  }"
                >
                  <div
                    :style="{
                      'color': (planned - in_fact) < 0 ? 'red' : ''
                    }"
                  >
                    {{ progress >= 100 ? '' : Math.round((planned - in_fact) * 10) / 10 }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="auto" class="col3">
              <div class="list__percent">
                {{ Math.trunc(progress) }}%
              </div>
            </v-col>
          </v-row>
        </v-container>

        <BudgetLegends type="detailed" :disabled="disableFlags"/>
      </div>
    </template>

  </div>
</template>

<script>
import CPLoading from '../../components/Dashboard/CPLoading.vue';
import { backNavigateQueue } from "@/helpers/app.js";
import dashboardApi from '@/helpers/dashboardApi';
import BudgetLegends from "../../components/Dashboard/BudgetLegends.vue";
import BudgetDatePicker from '@/components/Dashboard/BudgetDatePicker.vue'
import bridge from "@/helpers/bridge";
import { mapState } from "vuex";

export default {
  components: {
    CPLoading,
    BudgetLegends,
    BudgetDatePicker,
  },
  data() {
    return {
      loading: true,
      overall: null,
      budgetIndicatorsDetailed: [],
      projectId: parseInt(this.$route.params.projectId),
      type: this.$route.params.paramType,
      subtype: this.$route.params.paramTitle,
    }
  },
  computed: {
    date() {
      if (this.type === 'development') {
        return this.$store.state.cp.devDate;
      } else if (this.type === 'finance') {
        return this.$store.state.cp.finDate;
      } return null
    },
    total() {
      if (this.subtype === 'total') {
        return this.overall.total
      } else if (this.subtype === 'annual') {
        return this.overall.annual
      } return null
    },
    disableFlags() {
      return this.subtype === 'total' ? true : false
    },
    ...mapState("user", [
      "aituMeData"
    ]),
    payload() {
      return {
        userid: this.aituMeData.id
      }
    },
    watermark() {
      return this.$store.getters['cp/watermark'];
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    this.setTitle(this.title);
    bridge.disableScreenCapture();
    !this.watermark && this.$store.dispatch('cp/watermark', this.payload);
  },
  methods: {
    async getData() {
      try {
        const res = await dashboardApi(`/project/${this.projectId}/budget/${this.type}/${this.subtype}/${this.date}/`)
        this.budgetIndicatorsDetailed = res.data

        const res2 = await dashboardApi(`/project/${this.projectId}/budget/${this.type}/${this.date}/`)
        this.overall = res2.data
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    // Layout
    handleBackNavigation() {
      this.$router.replace({
        name: 'Budget'
      })
    },
    setTitle(t) {
      this.$store.dispatch('layout/setHeaderTitle', t);
    },
  },
  watch: {
    date(newValue) {
      newValue && console.log('wathcher ' + newValue);
      this.getData();
    },
  },
  beforeDestroy() {
    bridge.enableScreenCapture();
  },
}
</script>

<style lang="scss" scoped>
</style>