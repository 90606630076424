<template>
  <div class="program-container layout__body">
    
    <!-- search at the top -->
    <div class="programs_project__list-header">
      <SearchInput :textCentered="true"/>
    </div>

    <!-- information about the program -->
    <div class="program-info">
      <div class="program-name">
        <h3>
        {{ programInfo.manager }}
        </h3>
        <p>
          Руководитель программы
        </p>
      </div>
      <div class="program-budget">
        <div>
          <p>Общий бюджет</p>
        </div>
        <div>
          <p>{{programInfo.total_budget}} $</p>
        </div>
      </div>
      <div class="program-dates">
        <div>
          <p>{{ programInfo.start_date }}</p>
          <p class="program-dates-label">Дата начала</p>
        </div>
        <div>
          <p>{{ programInfo.end_date }}</p>
          <p class="program-dates-label">Дата окончания</p>
        </div>
      </div>
    </div>

    <!-- list of the project of the program -->
    <div
      v-for="{id, title} in projectsList"
      :key="id"
      class="project"
    >
      <router-link
        :to="{name: 'ProjectsPage', params: {id: id}}" replace
      >
        <div class="project-label">
          <p class="project-label-text">
            <span>#{{ 1 }}</span> {{ title }}
          </p>
        </div>
        <div class="nav-arrow-right">
          <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
        </div>
      </router-link>
    </div>
    <!-- end of the projects list -->
    <div class="emptyPrograms">
      <EmptyDash v-if="error" message="Нет проектов"/>
    </div>
</div>
</template>

<script>
import SearchInput from '../../components/partials/SearchInput.vue';
import { backNavigateQueue } from "@/helpers/app.js";
import dashboardApi from '@/helpers/dashboardApi';
import EmptyDash from '@/components/Dashboard/EmptyDash.vue'
import { mapActions } from "vuex";
import bridge from "@/helpers/bridge";

export default {
  components: {
    SearchInput,
    EmptyDash
  },
  data() {
    return {
      programId: this.$route.params.programId,
      programInfo: null,
      projectsList: null,
      divisionId: this.$route.params.divisionId,
    }
  },
  async created() {
    const res = await dashboardApi(`/division/${this.divisionId}/program/${this.programId}/`)
    this.programInfo = res.data

    const res2 = await dashboardApi(`/division/${this.divisionId}/program/${this.programId}/list/`)
    this.projectsList = res2.data
  },
  methods: {
    handleBackNavigation() {
      this.$router.replace({
        name: 'Projects'
      })
    },
    ...mapActions({
      resetBudget: 'cp/resetBudgetDates',
      resetMDR: 'cp/resetMdrDates',
      resetOto: 'cp/resetOto',
      resetBE: 'cp/resetBE',
      resetDocs: 'cp/resetDocs',
    })
  },
  mounted() {
    bridge.enableScreenCapture();
    backNavigateQueue.set(this.handleBackNavigation);
    this.resetBudget();
    this.resetMDR();
    this.resetOto();
    this.resetBE();
    this.resetDocs();
  },
  computed: {
    error() {
      if (this.projectsList.length === 0) {
        return true
      } return false
    }
  },
}
</script>

<style scoped>
.programs_project__list-header {
  padding: .5rem 1rem;
  background: #fff;
}
.program-container {
  background-color: #F9F9F9;
}

.program-info {
  border-radius: 0.75rem;
  background-color: #fff;
}
.program-info h3 {
  font-weight: 600;
  font-size: 1.75rem;
  color: #101828;
}

.program-info {
  padding: 1rem;
}

.program-info p {
  text-align: left;
}

.program-name h3{
  font-weight: 600;
  font-size: 1.75rem;
  color: #101828;
  line-height: 20px;
}

.program-name p {
  color: #8E8E93;
  padding: 0.25rem 0;
  font-size: .875rem;
}

.program-budget, .program-dates {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-top: 1rem;
}

.program-budget div,
.program-dates div {
  flex-basis: 8rem;
  /* padding: 1rem; */
}

.program-budget p,
.program-dates p {
  font-weight: 500;
  font-size: .875rem;
  line-height: 1.25rem;
  font-style: normal;
}

.program-dates {
  padding-top: 1rem;
}

.program-dates-label {
  color: #8E8E93
}

.folder, .project {
  display: flex;
  flex-direction: row;
  min-height: 4.25rem;
  background-color: #fff;
  align-items: center;
  padding: 0 1rem;
}

.nav-arrow-right {
  position: absolute;
  right: 1rem;
}

.project-label {
  display: flex;
  align-items: left;
}

.project-label-text span {
  color: grey;
}

.project-label-text {
  padding-right: 2.5rem
}

.emptyPrograms {
  height: 65vh;
}
</style>