<template>
  <div class="engineering_container layout__body">

    <!-- Loading -->
    <template v-if="loading">
      <CPLoading />
    </template>

    <!--
      Loaded
    -->
    <template v-if="!loading">

      <!-- 
        Header of the view
      -->
      <div class="engineering_title">
        
        <!-- link to mdr page -->
        <router-link
            class="engineering_title-link"
            :to="{name: 'EngineeringDetailed', params: { projectId: projectId}}"
            replace
        >
          <p @click="setTitle('MDR')">MDR</p>
        </router-link>

        <!-- calendar -->
        <div class="engineering_calendar" @click="toggeDatePicker">
          <Calendar :date="picker"/>
        </div>

        <!-- arrow -->
        <router-link
          :to="{name: 'EngineeringDetailed', params: { projectId: projectId}}"
          replace
        >
          <div @click="setTitle('MDR')" class="folder_nav_arrow">
            <img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt="">
          </div>
        </router-link>
      </div>

      <!-- 
        Body of the view
      -->
      <div class="graph_container" v-if="data && diff">
        <div class="graph">
    
          <!-- horisontal axis -->
          <div class="y-axis">
            <div
              v-for="(y, i) in yAxis"
              :key="i"
            >
              {{ y }}
            </div>
          </div>
    
          <!--
            Charts body
          -->
          <div class="chart">

            <!-- 
              1. designer chart
            -->
            <div class="cart-col designer-label">
              <div class="chart-col-1"
                :style="{
                  height: (data.designer_fact / data.planned) * 100 === 0 ? '3%' : (data.designer_fact / data.planned) * 100 + '%'
                }"
              >
                <div class="chart-col-bar">
                  <div class="chart-col-progress">{{ data.designer_fact }}</div>
                </div>
                <div class="chart-col-diff">{{ diff.designer_fact }}</div>
              </div>
            </div>
            
            <!--
              2. revision chart
            -->
            <div class="cart-col">
              <div class="chart-col-2"
                :style="{
                  height: (data.revision_fact / data.planned) * 100 === 0 ? '3%' : (data.revision_fact / data.planned) * 100 + '%',
                }">
                <div class="chart-col-bar">
                  <!-- label position absolute -->
                  <div class="chart-col-progress">{{ data.revision_fact }}</div> 
                </div>
                <div class="chart-col-diff">{{ diff.revision_fact }}</div>
              </div>
              <div class="space revision-label"
                :style="{
                  height: (data.designer_fact / data.planned) * 100 === 0 ? '3%' : (data.designer_fact / data.planned) * 100 + '%'
                }"
              >
              </div>
            </div>

            <!--
              3. customer chart
            -->
            <div class="cart-col">
              <div class="chart-col-3"
                :style="{
                  height: (data.customer_fact / data.planned) * 100 + '%'
                }"
              >
                <div class="chart-col-bar">
                  <!-- label position absolute -->
                  <div class="chart-col-progress chart-col-progress-last">{{ data.customer_fact }}</div>
                </div>
                <div class="chart-col-diff label-border-none">{{ diff.customer_fact }}</div>
              </div>
              <div class="space customer-label"
                :style="{
                  height: 
                    data.designer_fact === 0 && data.revision_fact === 0
                    ? '6%'
                    : data.designer_fact === 0 
                    ? ((data.designer_fact + data.revision_fact) / data.planned) * 100 + 3 + '%'  
                    : ((data.designer_fact + data.revision_fact) / data.planned) * 100 + '%'
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <!--
        DatePicker component
      -->
      <DatePicker 
        v-if="showDatePicker"
        v-on:closeOverlay="closeDatePicker($event)"
        v-on:monthChanged="getAvailableDates($event)"
        v-on:dateChanged="setCurrentDate($event)"
        :min="minimalMonth"
        :date="picker"
        :availableDates="availableDates"
        :loadingDates="loadingDates"
      />
    </template>
  </div>
</template>

<script>
import Calendar from '../../components/Dashboard/Calendar.vue';
import { backNavigateQueue } from "@/helpers/app.js";
import dashboardApi from '@/helpers/dashboardApi';
import DatePicker from '@/components/Dashboard/DatePicker.vue';
import CPLoading from '../../components/Dashboard/CPLoading.vue';
import axios from 'axios';
import bridge from "@/helpers/bridge";

export default {
  components: {
    Calendar,
    DatePicker,
    CPLoading,
  },
  data() {
    return {
      /*
        data
      */
        total: null,
        data: null,
        projectId: this.$route.params.projectId,
        diff: null,

      /*
        dates
      */

        availableDates: null,
        loadingDates: false,

      /*
        toggle
      */
        showDatePicker: false,
        loading: true,

      /*
        cancelToken
      */

        requestCancelToken: undefined,
    }
  },
  computed: {
    picker: {
      get: function () {
        return this.$store.state.cp.mdrPickerDate;
      },
      set: function (newValue) {
        return newValue;
      }
    },
    step: function () {
      // let result = Math.trunc(this.total / 24)
      // console.log(result)
      return 100
    },
    yAxis: function () {
      var arr = [];
      for (var i = 0; i <= this.total; i+= this.step) {
      arr.push(i);
      }
      return arr;
    },
    pickedMonth() {
      return this.picker.replace(/(\d+)-(\d+)-(\d+)/, '$1-$2')
    },
    minimalMonth() {
      let min = this.$store.state.cp.mdrMinDate;
      return min.replace(/(\d+)-(\d+)-(\d+)/, '$1-$2')
    },
  },
  async created() {
    
    /*
      get min max dates @ store
    */
 
      this.$store.dispatch('cp/setMdrDate', this.projectId)

      if (this.picker !== null) {
        this.getDataByDate(this.picker);
        this.getDiffByDate(this.picker);
      }
    
    /*
      get available dates by month
    */
      try {
        const res = await dashboardApi(`/project/${this.projectId}/mdr/date/${this.pickedMonth}/list/`);
        this.res = res.data;
      } catch (error) {
        console.log(error);
      }
  },
  methods: {
    handleBackNavigation() {
      this.$router.replace({
        name: 'ProjectsPage'
      })
    },
    closeDatePicker(i) {
      this.showDatePicker = i;
    },
    toggeDatePicker() {
      this.showDatePicker = !this.showDatePicker
    },
  /*
    get available dates by month
  */
    async getAvailableDates(month) {
      try {
        this.loadingDates = true
        if (typeof this.requestCancelToken != typeof undefined) {
          this.requestCancelToken.cancel("Operation canceled due to new request.")
        }
        this.requestCancelToken = axios.CancelToken.source()
        const availableDates = await dashboardApi(`/project/${this.projectId}/mdr/date/${month}/list/`,
        { cancelToken: this.requestCancelToken.token });
        this.availableDates = availableDates.data
        this.loadingDates = false;
      } catch (error) {
        console.log(error);
      } finally {
        // this.loadingDates = false;
      }
    },
  /*
    get data w/ date
  */
    async getDataByDate(date) {
      try {
        console.log('get data by w/ date called');
        const dataByDate = await dashboardApi(`/project/${this.projectId}/mdr/total/${date}/`);
        this.data = dataByDate.data;
        this.total = dataByDate.data.planned;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  /*
    set new date and get updated data
  */
    setCurrentDate(i) {
      this.$store.dispatch('cp/changedMdrDate', i);
    },
  /*
    get diff w date
  */
    async getDiffByDate(date) {
      try {
        const res = await dashboardApi(`/project/${this.projectId}/mdr/total/diff/${date}/`)
        this.diff = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    setTitle(title) {
      this.$store.dispatch('layout/setHeaderTitle', title);
    }
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    bridge.disableScreenCapture();
    this.setTitle('Инжиниринг');
  },
  watch: {
    picker() {
      this.getDataByDate(this.picker);
      this.getDiffByDate(this.picker);
    }
  },
  beforeDestroy() {
    bridge.enableScreenCapture();
  },
}
</script>

<style lang="scss" scoped>
</style>
