<template>
  <div class="building_container layout__body">

    <!-- loader -->
    <template v-if="loading">
      <CPLoading />
    </template>

    <!-- Content loaded -->
    <template v-if="!loading">
      <div class="header">
        <h2>Прогресс СМР по ключевым объектам</h2>
        <Calendar :date="picker"/>
      </div>

      <!-- folder -->
      <div
        v-for="{id, title, progress} in groups"
        :key="id"
      >
        <router-link
          :to="{name: 'BuildingFolder', params: { projectId: projectId, groupId: id}}" replace
        >
          <div class="information">
            <div class="folder">
              <img src="@/assets/dashboard/icons/Folder.svg" alt="">
            </div>
            <div class="information__label">
              <p>{{title}}</p>
            </div>
            <div class="arrow">
              {{ progress }}%
            </div>
          </div>
        </router-link>
      </div>
      <!-- end of folder -->
      <EmptyDash v-if="error" message="Нет информации"/>
    </template>
  </div>
</template>

<script>
import Calendar from '@/components/Dashboard/Calendar.vue'
import dashboardApi from '@/helpers/dashboardApi';
import { backNavigateQueue } from "@/helpers/app.js";
import EmptyDash from '@/components/Dashboard/EmptyDash.vue'
import CPLoading from '../../components/Dashboard/CPLoading.vue';


export default {
  components: {
    Calendar,
    EmptyDash,
    CPLoading
  },
  data() {
    return {
      loading: true,
      projectId: this.$route.params.projectId,
      list: null,
      groups: null,
      error: false,
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }
  },
  methods: {
    getIcon(color) {
      return `@/assets/dashboard/flag-icons/flag-${color}.svg`
    },
    handleBackNavigation() {
      this.$router.replace({
        name: 'ProjectsPage'
      })
    },
  },
  async created() {
    try {
      const res2 = await dashboardApi(`/project/${this.projectId}/building/fo/group/list/`)
      this.groups = res2.data
      this.groups.length === 0 ? this.error = true : ''
    } catch (error) {
      console.log(error);
      this.error = true;
    } finally {
      this.loading = false;
    }
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation)
  }
}
</script>

<style scoped>
.building_container {
  background: #F9F9F9;
}

h2 {
  color: #151C22;
  font-weight: 500;
  font-size: 1.24rem;
  line-height: 1.5rem;
}

.header {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem;
  background: #fff;
  border-radius: 18px 18px 0 0;
}
.body {
  background: #fff;
}
.empty-space {
  height: 25px;
}
.element {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr 1fr;
  color: rgba(16, 24, 40, 1);
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 400;
  padding: .25rem 1rem 0;
  gap: .5rem;
  align-items: center
}
.title > span {
  padding: 1px;
}

.title,
.type,
.percent {
  display: flex;
  align-items: center;
  background: #E0E0E0;
  border-radius: 12px;
  padding: 2px 4px;
}

.title {
  gap: .5rem;
  min-width: 100px;
}

.type {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3rem;
}
/* bar */
.bar {
  display: flex;
  align-items: center;
  border: 1px solid #DDE1E5;
  flex-grow: 1;
  position: relative;
  border-radius: 12px;
}

.filled,
.unfilled {
  display: flex;
  border-radius: 12px;
  padding: 2px 12px;
  justify-content: center;
}

.filled {
  background: #E0E0E0;
  min-width: 2rem;
  position: relative;
}

.added {
  text-align: center;
  position: absolute;
  right: 5px;
  top: -1.8rem;
  background: url('../../assets/dashboard/flag-icons/bubble-light.svg') no-repeat center center/cover;
  width: 30px;
  height: 25px;
}

.filled::after {
  content: var(--added);
  background: url('../../assets/dashboard/flag-icons/bubble-light.svg') no-repeat center center/cover;
  position: absolute;
  right: 0;
  width: 30px;
  height: 25px;
  text-align: center;
  right: 5px;
  top: -1.8rem;
  color: rgba(16, 24, 40, 1);
  font-weight: 600;
  font-size: .875rem;
}

.unfilled {
  background: #fff;
}

/* percent */
.percent {
  justify-content: center;
}

.legends {
  padding: 1rem;
  margin-top: 1rem;
}

.legend__element {
  display: flex;
  gap: .5rem
}

.legend__title {
  font-weight: 400;
  font-size: .8125rem;
  line-height: 1.25rem;
}

.type,
.bar {
  min-height: 20px;
}

/* folder classes */
.information {
  display: flex;
  align-items: center;
  padding: .5rem 12px;
  position: relative;
  border-bottom: 1px solid #DDE1E5;
  background: #fff;
}

.information__label {
  margin: .25rem;
}

.arrow {
  position: absolute;
  right: 12px;
}
</style>