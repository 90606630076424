<template>
  <div class="group">
    <div class="title">
      <p>{{ type }}, млн $</p>
    </div>
    <BudgetDatePicker :type="paramType"/>
  </div>
</template>

<script>
import BudgetDatePicker from '@/components/Dashboard/BudgetDatePicker.vue'

export default {
  components: {
    // Calendar,
    BudgetDatePicker,
  },
  props: {
    type: {
      type: String,
      required: true,
      default: 'Неопределенный тип бюджета',
    },
  },
  computed: {
    paramType() {
      return this.type === 'Освоение' ? 'development' : this.type === 'Финансирование' ? 'finance' : null;
    },
  },
}

</script>