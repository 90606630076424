<template>
	<v-overlay :opacity="fullscreen === true ? 1 : 0.4">
		<div class="hikvision">

			<div class="player-container" :class="{rotate: fullscreen}">
				<iframe :src="URL" :width="width" :height="height" frameborder="no" scrolling="no" allowfullscreen="true"></iframe>
			</div>

			<!-- btns -->
			<div v-if="!fullscreen" class="btns">
				<div class="btn">
					<img @click="close" src="@/assets/dashboard/icons/x-lg.svg" alt="">
				</div>
			</div>

			<!-- fullscreen btns -->
			<div v-if="fullscreen">
				<div class="close">
					<div class="btn_fs">
						<img @click="close" src="@/assets/dashboard/icons/x-lg.svg" alt="">
					</div>
				</div>
				<div class="toggleFS">
					<div class="btn_fs">
						<img @click="setFullscreen" src="@/assets/dashboard/icons/fullscreen-exit.svg" alt="">
					</div>
				</div>
			</div>
		</div>
	</v-overlay>
</template>

<script>

export default {
	props: {
		URL: {
			type: String
		}
	},
	data() {
		return {
			// curPlayer: null,
			// curJSPlay: null,
			// hlsPlayer: null,
			// sldpPlayer: null,
			// js decoder config
			// sldpConf: {
			// 	container: 'player',
			// 	stream_url: 'wss://cp-dashboard.erg.kz:443/wsscam',
			// 	adaptive_bitrate: {
			// 		initial_rendition: '240p'
			// 	},
			// 	buffering: 500,
			// 	autoplay: true,
			// 	height: 480,
			// 	width: 424,
			// 	fullscreen: false,
			// },
			// hlsConfig: {
			// 	controls: true,
			// 	autoplay: true,
			// 	preload: 'auto',
			// 	width: 640
			// },

			// layout
			overlay: true,

			// fullscreen
			fullscreen: false,
			fsWidth: null,
			fsHeight: null,
			width: null,
			height: null,
		}
	},
	created() {
		// default width / height
		this.width = window.innerWidth;
		this.height = (window.innerWidth * 66.6) / 100;

		// fullscreen
		this.fsHeight = window.innerWidth;
		this.fsWidth = Math.trunc(window.innerWidth + (window.innerWidth * 66.6) / 100);
	},
	mounted() {
		// this.initPlayer()
		// this.curPlayer = document.getElementById('videojs_player')
	},
	methods: {
		close() {
			this.$emit('close')
		},
		// initPlayer(){ 
		// 		var videoSrc = 'http://10.144.163.100:8081/live/1/playlist.m3u8';
		// 		var video = document.getElementById('videojs_player');
		// 		console.log(video)
		// 		if (video.canPlayType('application/vnd.apple.mpegurl')) {
		// 			video.src = videoSrc;
		// 			//
		// 			// If no native HLS support, check if HLS.js is supported
		// 			//
		// 		} else {
		// 			// this.hlsPlayer = window.videojs('videojs_player', this.hlsConfig);
		// 			// this.hlsPlayer.src(videoSrc);
		// 			// this.hlsPlayer.ready(() => {
		// 			// 	const playPromise = this.hlsPlayer.play();
		// 			// 	if (playPromise !== undefined) {
		// 			// 	playPromise.then(() => {
		// 			// 		// Automatic playback started!
		// 			// 		// Show playing UI.
		// 			// 	})
		// 			// 	.catch(error => {
		// 			// 		console.log(error)
		// 			// 		// Auto-play was prevented
		// 			// 		// Show paused UI.
		// 			// 	});
		// 			// }
		// 			// });
		// 		}
    //   },
    removePlayer(){
        // this.sldpPlayer.destroy(function () {
        //   console.log('SLDP Player is destroyed.');
        // });
				// var oldPlayer = document.getElementById('videojs_player').outerHTML;
				// window.videojs(this.curPlayer).dispose();

      }
	},
	beforeDestroy() {
		this.removePlayer()
	},
}
</script>

<style lang="scss" scoped>
</style>
