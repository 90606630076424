<template>
  <div class="budget__container layout__body">
    
    <!-- 
      Loading
    -->
    <template v-if="devLoading && finLoading">
      <CPLoading />
    </template>

    <!-- 
      Loaded
    -->

    <template v-if="!devLoading && !finLoading && watermark">
      <div :style="{backgroundImage: watermark}" class="dash__watermark">
      <div
        v-for="({total, annual, date}, i) in items"
        :key="i"
        class="wrapper"
      >
        <!-- Group -->
        <BudgetGroup 
          @toggle="toggleDatePicker($event)"
          :type="total.type"
        ></BudgetGroup>

        <!-- Total budget-->
        <div class="item">
          <BudgetHeader
            :projectId="projectId"
            :title="total.title"
            :type="total.type"
          />
          <BudgetProgress :item="total"/>
        </div>

        <!-- Annual budget -->
        <div class="item">
          <BudgetHeader
            :projectId="projectId"
            :title="annual.title"
            :type="total.type"
          />
          <BudgetProgress :item="annual" :flag="true"/>
        </div>
      </div>

      <BudgetLegends type="main"/>
      </div>
    </template>

    <!--
      Date picker
    -->

    <DatePicker
      v-if="showDatePicker"
      v-on:closeOverlay="toggleDatePicker($event)"
      v-on:monthChanged="monthChanged($event)"
      v-on:dateChanged="setNewDate($event)"
      :min="budgetMinDate"
      :date="datePickerType == 'Освоение' ? devDate : datePickerType == 'Финансирование' ? finDate : null"
      :availableDates="availableDates"
      :loadingDates="loadingDates"
    />
  </div>
</template>

<script>
import { backNavigateQueue } from "@/helpers/app.js";
import dashboardApi from '@/helpers/dashboardApi';
import CPLoading from '@/components/Dashboard/CPLoading.vue';
import DatePicker from '@/components/Dashboard/DatePicker.vue';
import axios from 'axios';
import bridge from "@/helpers/bridge";
import { mapState } from "vuex";

// Budget components
import BudgetHeader from '@/components/Dashboard/BudgetHeader.vue';
import BudgetProgress from '@/components/Dashboard/BudgetProgress.vue';
import BudgetGroup from "../../components/Dashboard/BudgetGroup.vue";
import BudgetLegends from "../../components/Dashboard/BudgetLegends.vue";

export default {
  components: {
    CPLoading,
    DatePicker,
    BudgetHeader,
    BudgetProgress,
    BudgetGroup,
    BudgetLegends,
  },
  data() {
    return {
      /*
        states
      */
      projectId: parseInt(this.$route.params.projectId),
      devLoading: true,
      finLoading: true,

      /*
        data
      */

      dev: null,
      fin: null,

      /*
        date picker
      */
      
      showDatePicker: false,
      datePickerType: null,   
      availableDates: null,
      loadingDates: false,
    
      /*
        cancelToken
      */
      
      requestCancelToken: undefined,
    }
  },
  computed: {
    // get min dates
    budgetMinDate() {
      let min = this.$store.state.cp.budgetMinDate;
      return min.replace(/(\d+)-(\d+)-(\d+)/, '$1-$2')
    },
    devDate() {
      return this.$store.state.cp.devDate;
    },
    finDate() {
      return this.$store.state.cp.finDate
    },
    items() {
      return [
        {...this.dev, date: this.devDate},
        {...this.fin, date: this.finDate},
      ]
    },
    ...mapState("user", [
      "aituMeData"
    ]),
    payload() {
      return {
        userid: this.aituMeData.id
      }
    },
    watermark() {
      return this.$store.getters['cp/watermark'];
    },
  },
  async created() {
    this.$store.dispatch('cp/setBudgetInitDates', this.projectId);
    this.devDate && this.getDev(this.devDate);
    this.finDate && this.getFin(this.finDate);
    // getWatermark
  },
  methods: {
    /*
      get data
    */

    async getDev(date) {
      try {
        const res = await dashboardApi(`/project/${this.projectId}/budget/development/${date}/`);
        this.dev = res.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.devLoading = false;
      }
    },
    
    async getFin(date) {
      try {
        const res = await dashboardApi(`/project/${this.projectId}/budget/finance/${date}/`);
        this.fin = res.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.finLoading = false;
      }
    },

    /* 
      DatePicker
    */

    async monthChanged(month) {
      try {
        this.loadingDates = true;
        if (typeof this.requestCancelToken != typeof undefined) {
          this.requestCancelToken.cancel("Operation canceled due to new request.")
        }
        this.requestCancelToken = axios.CancelToken.source()
        const res = await dashboardApi(`/project/${this.projectId}/budget/total/date/${month}/`,
        { cancelToken: this.requestCancelToken.token });
        this.availableDates = res.data;
        this.loadingDates = false;
      } catch (error) {
        console.log(error);
      }
    },

    setNewDate(event) {
      if (this.datePickerType === 'Освоение') {
        this.$store.dispatch('cp/changeDevDate', event)
      } else if (this.datePickerType === 'Финансирование') {
        this.$store.dispatch('cp/changeFinDate', event);
      } return null
    },

    toggleDatePicker(e) {
      this.datePickerType = e;
      this.showDatePicker = !this.showDatePicker;
    },

    /* 
      layout
    */
   
    setTitle(t) {
      this.$store.dispatch('layout/setHeaderTitle', t);
    },

    handleBackNavigation() {
      this.$router.replace({
        name: 'ProjectsPage'
      })
    },
  },
  watch: {
    devDate() {
      this.getDev(this.devDate);
    },
    finDate() {
      this.getFin(this.finDate);
    },
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    this.setTitle('Бюджетные показатели');
    bridge.disableScreenCapture();
    !this.watermark && this.$store.dispatch('cp/watermark', this.payload);
  },
  beforeDestroy() {
    bridge.enableScreenCapture();
  },
}
</script>

<style scoped>
</style>