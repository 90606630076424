var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"building_folder_container layout__body"},[(_vm.loading)?[_c('CPLoading')]:_vm._e(),(!_vm.loading)?[_c('div',{staticClass:"body"},[_vm._l((_vm.list),function(ref){
var id = ref.id;
var title = ref.title;
var unit = ref.unit;
var planned_count = ref.planned_count;
var in_fact = ref.in_fact;
var flag = ref.flag;
var progress = ref.progress;
return _c('div',{key:id,staticClass:"element-building_folder_container"},[_c('div',{staticClass:"empty-space"}),_c('div',{staticClass:"element"},[_c('div',{staticClass:"title"},[_c('div',[_c('img',{staticClass:"flag",attrs:{"src":require(("@/assets/dashboard/flag-icons/flag-" + flag + ".svg")),"alt":""}})]),_c('div',[_c('p',[_vm._v(_vm._s(title))])])]),_c('div',{staticClass:"type"},[_vm._v(_vm._s(unit === 'человекочасы' ? 'ч/час' : unit))]),_c('div',{staticClass:"bar"},[_c('div',{class:{filled: in_fact},style:({'width': progress === 100 ? '100%' 
              : progress === 0 ? '0%'
              : progress >= 70 ? 70 + '%'
              : ( (planned_count - in_fact) / planned_count ) * 100 + '%' })},[_vm._v(" "+_vm._s(in_fact === 0 ? '' : in_fact)+" ")]),(planned_count - in_fact !== 0)?_c('div',{staticClass:"unfilled",style:({'width': progress === 100 ? '0%' 
              : progress === 0 ? '100%'
              : progress >= 70 ? 30 + '%'
              : ( (planned_count - in_fact) / planned_count ) * 100 + '%' })},[_c('div',[_vm._v(" "+_vm._s(planned_count - in_fact))])]):_vm._e()]),_c('div',{staticClass:"percent"},[_vm._v(_vm._s(progress)+"%")])])])}),_c('div',{staticClass:"legends"},_vm._l((_vm.legends),function(ref,i){
              var flag = ref.flag;
              var title = ref.title;
return _c('div',{key:i,staticClass:"legend"},[_c('div',{staticClass:"legend__element"},[_c('img',{staticClass:"flag",attrs:{"src":require(("@/assets/dashboard/flag-icons/flag-" + flag + ".svg")),"alt":""}}),_c('span',{staticClass:"legend__title"},[_vm._v(_vm._s(title))])])])}),0)],2)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }